import React, { useEffect, useContext } from "react";
import TableSection from "./TableSection";
import { UsersContext } from "../../context/UsersContext";
import * as yup from "yup";

const headCells = [
    {
        id: "id",
        numeric: true,
        disablePadding: false,
        label: "ID",
        type: "string",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
        type: "string",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        type: "string",
    },
];

const extraCrudFields = [
    {
        id: "password",
        disablePadding: false,
        label: "Password",
        type: "password",
    },
    {
        id: "confirmPassword",
        disablePadding: false,
        label: "Confirm Password",
        type: "password",
    },
];

const validationSchemaAdd = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().required("required").email("Enter a valid email"),
    password: yup
        .string()
        .required("required")
        .min(8, "Your password is too short."),
    confirmPassword: yup
        .string()
        .required("Please retype your password.")
        .oneOf([yup.ref("password")], "Your passwords do not match."),
});

const validationSchemaUpdate = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().required("required").email("Enter a valid email"),
    password: yup.string().matches(/^(|.{8,})$/, {
        excludeEmptyString: true,
        message: "Your password is too short.",
    }),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Your passwords do not match.")
        .test(
            "passwords-match",
            "Your passwords do not match.",
            function (value) {
                return this.parent.password === value;
            }
        ),
});

const UsersTable = () => {
    const { users, fetchUsersList, addUser, updateUser, deleteUser } =
        useContext(UsersContext);
    const usersMapped = users?.map((user) => {
        return {
            id: user.id,
            name: user.name,
            email: user.email,
        };
    });

    useEffect(() => {
        if (usersMapped?.length === 0) {
            fetchUsersList();
        }
    }, []);

    return (
        <TableSection
            headCells={headCells}
            extraCrudFields={extraCrudFields}
            validationSchemaAdd={validationSchemaAdd}
            validationSchemaUpdate={validationSchemaUpdate}
            titlePlural={"Users"}
            titleSingle={"user"}
            defaultSortField={"date"}
            data={usersMapped}
            onAddEvent={addUser}
            onUpdateEvent={updateUser}
            onDeleteEvent={deleteUser}
        />
    );
};

export default UsersTable;
