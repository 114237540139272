import React from "react";
import { Link } from "react-router-dom";
import reliqideusFront from "./../../media/reliqideus-front.jpeg";
import { routes } from "./../routes";
import styled from "styled-components";
import MainPageLayout from "../components/MainPageLayout";
import AnimatedElement from "../components/AnimatedElement";
import SectionHeader from "../components/SectionHeader";

const StoriesLayout = styled.div`
    margin: 10vh 2vw 5vh 2vw;
    display: flex;
    flex-direction: column;
    width: min(26rem, 80%);
    height: fit-content;
`;

const LinkStyled = styled(Link)`
    max-width: min(90%, 20rem);
    display: flex;
    justify-content: center;
`;

const ImgStyled = styled.img`
    border-radius: 30px;
    max-width: inherit;
    opacity: 0.7;

    :hover {
        box-shadow: 0px 0px 5px 1px white;
        opacity: 1;
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const StoriesOverview = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <AnimatedElement>
                    <SectionHeader>Select an album:</SectionHeader>
                </AnimatedElement>
                <AnimatedElement>
                    <LinkWrapper>
                        <LinkStyled to={routes.reliqideus}>
                            <ImgStyled src={reliqideusFront} />
                        </LinkStyled>
                    </LinkWrapper>
                </AnimatedElement>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StoriesOverview;
