import React, { useContext, useState } from "react";
import { protectedRoutes, routes } from "./../routes";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogOut from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import SettingsSection from "./SettingsSection";
import { AccountContext } from "./../context/AccountContext";

const HeaderStyled = styled.nav`
    height: 35px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 1rem 10px;
    background-color: #0a0a0a;
    backface-visibility: hidden;
`;

const StyledLink = styled(Link)`
    display: inline-block;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    margin: 0 25px;
    line-height: inherit;
    white-space: nowrap;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: none;
    color: #fff9;
`;

export const StyledBurger = styled.button`
    position: absolute;
    top: 22%;
    left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: scale(0.8);
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 2rem;
        height: 0.15rem;
        border-radius: 10px;
        transition: all 0.3s linear;
        background-color: white;
        position: relative;
        transform-origin: 1px;
    }

    @media (min-width: 700px) {
        display: none;
    }
`;

const NavContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: fixed;

    @media (max-width: 700px) {
        display: none;
    }
`;

const IconContainer = styled.div`
    margin-left: auto;
`;

const Burger = ({ showMobileMenu, setShowMobileMenu }) => {
    return (
        <StyledBurger
            showMobileMenu={showMobileMenu}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
            <div />
            <div />
            <div />
        </StyledBurger>
    );
};

const Header = ({ showMobileMenu, setShowMobileMenu }) => {
    const { account, logout } = useContext(AccountContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const history = useHistory();

    return (
        <HeaderStyled>
            <Burger
                showMobileMenu={showMobileMenu}
                setShowMobileMenu={setShowMobileMenu}
            />
            <NavContainer>
                <StyledLink to={routes.main}>Main</StyledLink>
                <StyledLink to={routes.bio}>Bio</StyledLink>
                <StyledLink to={routes.shows}>Shows</StyledLink>
                <StyledLink to={routes.stories}>Stories</StyledLink>
                <StyledLink to={{ pathname: routes.store }} target="_blank">
                    Store
                </StyledLink>
            </NavContainer>
            <IconContainer>
                {account.loggedIn ? (
                    <>
                        <IconButton
                            onClick={() => {
                                setDrawerOpen(!drawerOpen);
                            }}
                        >
                            <Settings sx={{ color: "#b1b1b1" }} />
                        </IconButton>
                        <Drawer
                            anchor={"right"}
                            PaperProps={{
                                sx: {
                                    top: "55px",
                                    border: "1px solid rgba(255, 255, 255, 0.12)",
                                    backgroundImage:
                                        "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))",
                                    width: "min(300px, 100vw)",
                                    fontFamily: "inherit",
                                },
                            }}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                        >
                            <Box role="presentation">
                                <SettingsSection />
                            </Box>
                        </Drawer>
                        <IconButton
                            onClick={() => {
                                history.push(protectedRoutes.adminDashboard);
                            }}
                        >
                            <AccountCircle sx={{ color: "#b1b1b1" }} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                logout();
                            }}
                        >
                            <LogOut sx={{ color: "#b1b1b1" }} />
                        </IconButton>
                    </>
                ) : null}
            </IconContainer>
        </HeaderStyled>
    );
};

export default Header;
