import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { routes, componentTree, protectedComponentTree } from "./../routes";
import AnimatedHeader from "./AnimatedHeader";
import { ProtectedRoute } from "./routing/ProtectedRoute";
import { ShowsProvider } from "../context/ShowsContext";
import { UsersProvider } from "../context/UsersContext";
import { AccountProvider } from "../context/AccountContext";
import { SettingsProvider } from "../context/SettingsContext";

export const PageStyled = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: overlay;
    overflow-x: hidden;
`;

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        text: {
            primary: "#fff9",
        },
    },
    typography: {
        fontFamily: "Century Gothic, Century Gothic, Apple Gothic, sans-serif",
    },
    components: {
        MuiTypography: {
            defaultProps: {
                fontFamily:
                    "Century Gothic, Century Gothic, Apple Gothic, sans-serif",
            },
        },
        MuiFormControlLabel: {
            defaultProps: {
                fontSize: "5px",
            },
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <BrowserRouter>
                <SettingsProvider>
                    {" "}
                    <ShowsProvider>
                        <UsersProvider>
                            <AccountProvider>
                                <AnimatedHeader />
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to={routes.main} />
                                    </Route>
                                    <Route exact path="/cultmeister">
                                        <Redirect to={routes.adminLogin} />
                                    </Route>
                                    {componentTree.map(
                                        ({ path, Component }) => (
                                            <Route key={path} exact path={path}>
                                                {({ match }) => (
                                                    <CSSTransition
                                                        in={match !== null}
                                                        timeout={1000}
                                                        classNames="page"
                                                        unmountOnExit
                                                    >
                                                        <PageStyled>
                                                            <Component />
                                                        </PageStyled>
                                                    </CSSTransition>
                                                )}
                                            </Route>
                                        )
                                    )}
                                    {protectedComponentTree.map(
                                        ({ path, Component }) => (
                                            <ProtectedRoute
                                                key={path}
                                                exact
                                                path={path}
                                                component={Component}
                                            />
                                        )
                                    )}
                                    <Route exact path="/cultmeister/*">
                                        <Redirect to={routes.adminLogin} />
                                    </Route>
                                    <Route exact path="*">
                                        <Redirect to={routes.main} />
                                    </Route>
                                </Switch>
                            </AccountProvider>
                        </UsersProvider>
                    </ShowsProvider>
                </SettingsProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

ReactDOM.render(<App />, document.getElementById("app"));
