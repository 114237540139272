import styled from "styled-components";

const SectionHeader = styled.div`
    width: 100%;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: min(6vw, 18px);
    font-weight: 300;
    line-height: 1.6;
    align-self: center;
    text-align: center;
    padding-bottom: 10px;
    text-decoration: none;
    color: #fff9;
    border-bottom: 1px solid;
    margin: 1em 0;
    border-image: linear-gradient(
            to right,
            rgba(10, 10, 10, 0) 0 20%,
            #d6dee1 45% 55%,
            rgba(10, 10, 10, 0) 80% 100%
        )
        20%;
    border-image-width: 0px 0px 1.5px 0px;
`;

export default SectionHeader;
