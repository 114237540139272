import React, { useContext } from "react";
import styled from "styled-components";
import { SettingsContext } from "./../context/SettingsContext";

export const StoriesLayout = ({ children }) => {
    const { storyFontSize } = useContext(SettingsContext);

    return (
        <StoriesBaseLayout>
            <div style={{ fontSize: storyFontSize }}>{children}</div>
        </StoriesBaseLayout>
    );
};

const StoriesBaseLayout = styled.div`
    margin: 12vh 2vw 5vh 2vw;
    display: flex;
    flex-direction: column;
    width: min(40rem, 80%);
    height: fit-content;
    font-family: Century Gothic;
    font-size: clamp(0.8rem, 3vw, 0.95rem);
    font-weight: 400;
    line-height: 1.8;
    color: white;
`;

export const StoryHeaderStyled = styled.h2`
    text-align: center;
    align-items: center;
    align-self: center;
    display: flex;
    font-weight: 100;
`;

export const StorySubHeaderStyled = styled.h3``;

export const ParagraphStyled = styled.p`
    > em {
        font-family: Century Gothic;
        line-height: 0.1em;
        font-style: none;

    }
    }
`;
