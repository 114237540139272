import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";

const StoryElLizardBirth = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <StoryHeaderStyled>
                    <BackButton route={routes.reliqideus} y={0} />
                    El Lizard Birth
                </StoryHeaderStyled>
                <StorySubHeaderStyled>
                    London – February 11th 1887
                </StorySubHeaderStyled>
                <ParagraphStyled>
                    <em>
                        Winter has fallen late upon the streets of Westminster.
                        Tiny snowflakes attached themselves to twirling parts of
                        soot ascending from the chimneys, majestically rising
                        above the roofs of Horseferry Road. The flakes landed as
                        a molten, grey squash of mud upon the deserted Vincent
                        Square. Despite the lack of snow and ice, the world
                        still felt cold. It is not the freezing veil of winter
                        itself that had caused this miserable aura around the
                        people of Great-Britain, for it is only since the most
                        recent events that a collective uncertainty had slowly
                        begun to creep into their daily affairs…
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    On the corner of the street lives Martin Cook, a
                    fifty-four-year-old salesman, by himself. Horseferry Road is
                    inhabited mostly by men of greater wealth. Lawyers,
                    politicians, and other treasured men. Martin never
                    considered himself one of them. He had earned his fair share
                    by working hard and living meagerly. He always saved as much
                    as he could throughout his entire adult life so one day he
                    could buy himself the house he so desired. Ever since he
                    started making money, it had been like an obsession to him.
                    So much so that his wife divorced him over it, over twelve
                    years ago. But his approach did pay off, and it had been
                    five years already since Martin moved to London. At first he
                    couldn’t quite decide whether to live amidst the bustle of
                    the city or the calming silence of the countryside. He was
                    raised in Washbrook, just outside of Ipswich, so that
                    typical scent of the meadows there did have a great
                    nostalgic feel to it. But on the other hand, he just
                    couldn’t resist the convenience of being in the dead center
                    of a rumbling city, something he’d already become completely
                    accustomed to after living in Cambridge for over twenty
                    years. So even though it had been a tough decision, London
                    had proven to be a satisfying choice. Now, most of the time,
                    Martin enjoyed his life, with the lively sound of the city
                    ever present in the background. To Martin, especially the
                    Friday nights in London were often rather exuberant, or
                    pleasant at least. But not this one.
                </ParagraphStyled>
                <ParagraphStyled>
                    Six long days had gone by since Prime Minister Robert Cecil
                    announced the death of Queen Victoria in a five minute
                    speech. No elucidation, nor encouraging plans to provide the
                    anxious citizens with a glimmer of hope. Just the hard
                    facts, a reminder of their consequences and a not too
                    friendly reminder to keep calm. Needless to say, the people
                    experienced a collective mixture of grief and panic over the
                    loss of their queen, but somehow it also seemed that - ever
                    since that day - the people of London had disconnected. The
                    only conversations publically taking place consisted of
                    nothing but vague rumors, unfounded accusations and strange
                    theories on what could have caused all this. In all this
                    uncertainty, people’s imaginations were running wild. People
                    even claimed to have seen tall unearthly beings entering the
                    borders of the city, cloaked in medieval mantles, slowly
                    wandering the streets like monks during a sacred ceremony.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Martin hadn’t spoken to his friends or relatives for a week
                    now. That’s not that much of a rarity, although he usually
                    kept up a regular correspondence with his nephew Arthur,
                    from Ipswich. At this moment Martin was sitting comfortably
                    in his green velvet armchair, facing the bookcase in his
                    study. Not staring at one particular point or thinking about
                    one particular subject, he let out an intense sigh, feeling
                    quite tired. Inhaling the dusty air he tasted the cigarette
                    he smoked half an hour ago. Raising his right hand, he
                    rediscovers the glass of bourbon in it that by now had
                    adopted the temperature of his sweaty palm. Martin finished
                    the drink, rose from his chair and reached for his journal,
                    quill and ink. Taking place behind his desk he glanced at
                    the clock, dipped his quill into the ink and started
                    writing:
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>“Friday, February 11th 8:30pm</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>...Another quiet day. I’ve had...”</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Martin stopped writing. He had noticed an unusual sound
                    coming from the front side of the street. He dropped his
                    quill, got up from his chair, and started walking toward the
                    window to take a look outside. At first, the dim lights of
                    the lanterns didn’t offer him much of a view in the foggy
                    décor of Horseferry Road. But he kept watching, patiently.
                    Suddenly, at the entrance to Marsham Street, he discovered
                    three slowly moving figures. Immediately, Martin started
                    feeling nauseated, as if he had eaten bricks for dinner. The
                    figures kept moving closer, and he noticed their ragged
                    cloaks as they seemed to be approaching the front doors on
                    his street. “Damn beggars”, he mutters, and moves away from
                    the window. Martin didn’t understand why, but he did not
                    feel quite well, still somewhat nauseous and very noticeably
                    lightheaded. “That’s enough booze for a Friday night,” he
                    continued mumbling to himself. He returned to his desk to
                    continue writing in his journal. Once again, Martin checked
                    the time before starting to write, more out of habit than
                    necessity. It takes some time before he understands what he
                    sees. For a brief moment, it seemed like he has forgotten
                    how to read a clock. He tightened his eyes and tried to
                    focus his mind, until the arms on his clock started to makes
                    sense to him again. 9:30 p.m., it said. He tried to continue
                    writing, but stopped directly as he saw what he had just
                    written at the top of the page - in his mind no more than
                    two minutes ago: “<em>8:30 p.m..</em>” ‘
                    <em>That’s impossible’</em>, he thought. He couldn’t have
                    just casually skipped an hour… Confused, he looked at his
                    clock again. A rising sensation of intoxication started to
                    take a hold of him. Martin tried to focus on the arms of the
                    clock again — a clock he knew all too well, one he had owned
                    and trusted for decades — he saw that the device now
                    appeared to somehow have many more than just two arms, all
                    turning in different directions at changing speeds. Martin’s
                    hands started shaking badly as he reached to find his
                    handkerchief. He was clearly not thinking straight, and
                    tried to comfort himself with something familiar. He finally
                    felt the soft satin handkerchief, and as he touched it he
                    noticed that it was softer, or maybe thinner than usual. It
                    didn’t matter. He pushed the satin fabric against his sweaty
                    forehead. At first it felt good, or at least just as he
                    expected it to feel. But after a few seconds, Martin lost
                    the handkerchief, as if his sweat has dissolved the thing
                    and made it disappear completely.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    At this point Martin started to really lose it. The bells
                    from Saint John’s church began to chime loudly.{" "}
                    <em>‘A beacon of sanity’</em>, Martin immediately thought,
                    as he tried to count the times he heard the deep bells
                    chime. Be he quickly lost track of the count, being far too
                    confused to stay focused. One thing he did still notice very
                    clearly: the bells did not stop. The sound persevered and
                    incisively penetrated his conscious thoughts. Its
                    repetitive, monotone strikes took over his mind and from
                    that point Martin was completely unable to think in the way
                    he usually did. Trapped in a daze, he realized the bells did
                    not sound anything like bells anymore. The sound had
                    transformed into that of a pounding on thick wood, heavy and
                    loud. Slowly he understood it was the sound of someone
                    knocking on his door.
                </ParagraphStyled>
                <ParagraphStyled>
                    In his right mind Martin would never open the door for
                    beggars or any other unexpected guests, especially at this
                    late hour. But in his current psychotic state he had no
                    choice but to stand up and slowly move towards the door of
                    his study. As he walked through the doorway, he saw
                    disturbing geometric patterns moving and breathing on the
                    walls, which disoriented him even more. Even though he saw
                    what was happening, Martin was in no position to take
                    control over his actions. He entered the upstairs hallway.
                    This hallway and the connecting stairs it led to were among
                    the most beautifully decorated areas in Martin’s house. A
                    small girandole illuminated the long corridor just enough to
                    expose the bleak and static faces displayed on the paintings
                    on the wall. Step by step Martin went down the creaking
                    stairs, still accompanied by the everlasting pounding on the
                    front door in the rhythm of the bells of Saint John’s
                    church. When he reached the downstairs hallway, he was only
                    three feet removed from his front door. Standing there,
                    motionless, Martin had a brief moment of clarity, as all of
                    a sudden the intense pounding stopped only to be replaced by
                    an ear-splitting silence. Martin was breathing heavily and
                    his entire body was shaking. He heard a clicking sound
                    coming from within the door itself. Slowly, the door started
                    opening. A soft but freezing wind slowly crept into the
                    house and struck Martin’s pale face. A large scaled hand
                    grabbed the door and pushed it open further…
                </ParagraphStyled>
                <ParagraphStyled>
                    As the door hit the wall with a loud bang, Martin was
                    confronted by three cloaked beings stepping into his house.
                    They were unimaginably tall, seven feet at least. In the
                    scarce light it was hard to see much detail, but Martin
                    still was fairly sure he caught a glimpse of amphibious
                    faces hiding under their mantles. The figure in the middle
                    carried an ancient looking book. Parchment bound in battered
                    leather. Then, holding the book out with both hands, it
                    spoke out in a screeching voice:
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        “Can you spare a minute to talk about our lord and
                        savior?!”
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Martin kept staring at the imposing creatures. Before he had
                    a chance to respond to the utterly strange request, the
                    creature opened the book and presented the first page to
                    him. It showed wonderful artwork depicting a colorful symbol
                    mounted by very steady hands. Winding tentacles appeared
                    from the outside edges of the symbol of the all-seeing eye.
                    Fully mesmerized, Martin gazed into the pitch black pupil of
                    the eye. Suddenly, the creature grabbed Martin and pushed
                    its long scaly thumb firmly into the middle of Martin’s
                    forehead. He immediately felt a force pulling from behind,
                    and with that overwhelming feeling the whole world around
                    him disappeared as he fell into a deep and profound state of
                    trance. In this elusive state, Martin started receiving odd
                    visions. It began with Queen Elizabeth, walking down a
                    stairway in the middle of a cemetery. She was clad in a
                    gracious fifteenth century dress, eagerly being welcomed by
                    many twisting tentacles, followed by an intense flash of
                    light. A gloomy scene of vast meadows appeared. Between the
                    wisps of fog, on the edge of the gaping entrance to a cave,
                    there was an enormous stone monolith. A deep dark voice
                    whispered: <em>“We come from deep below...”</em> The fog
                    slowly dissolved.{" "}
                    <em>
                        “We are summoned by the old ones to serve Her Awful
                        Majesty!”{" "}
                    </em>
                    From the depths of the cave a colony of reptile-like
                    creatures marched in slow motion, fanning outwards.{" "}
                    <em>“We are many…”</em> the voice continued. Another flash
                    of light occurred, and now queen Victoria was sitting behind
                    her mirror, brushing her hair. A reptilian creature appeared
                    behind her, grabbing her hair, as another one slashed the
                    queen’s throat with an ancient looking dagger made of black
                    metal. Blood flowed abundantly from the queens’ incurable
                    wounds, coloring her perfectly white dress in an intense
                    deep red shade. The queen and the room disappeared into the
                    void. Now, a wooden cradle appeared.{" "}
                    <em>“El Lizard Birth is born!”, </em>&nbsp;the voice
                    whispered with a deep intensity. Laying in the cradle, the
                    vision showed Martin a monstrously misshapen newborn lizard
                    creature with slimy tentacles and a most vicious grin.
                </ParagraphStyled>
                <ParagraphStyled>
                    As the visions came to a sudden halt, Martin found himself
                    staring at the last page in the book, which was blank. He
                    now realized, there was only one thing left to do. He filled
                    his lungs with air, and in a shrieking voice he proclaimed:{" "}
                    <em>“All hail El Lizard Birth!!”</em>.
                </ParagraphStyled>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StoryElLizardBirth;
