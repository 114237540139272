import React, { useReducer, createContext } from "react";
import { usersReducer, initialState } from "./reducers/usersReducer";
import httpClient from "../auth/httpClient";

export const UsersContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const UsersProvider = ({ children }) => {
    const [state, dispatch] = useReducer(usersReducer, initialState);

    const fetchUsersList = async () => {
        dispatch({ type: "SET_LOADING" });
        try {
            const result = await httpClient.get("/api/users", {
                withCredentials: true,
            });
            dispatch({ type: "FETCH_LIST", payload: result.data });
        } catch (error) {
            dispatch({ type: "FETCH_LIST_ERROR", payload: error.data });
        }
    };

    const addUser = async (user) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.post("/api/users", {
                user,
            });
            dispatch({ type: "ADD_USER", payload: result.data });
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    const updateUser = async (user) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.patch(`/api/users/${user.id}`, {
                user,
            });
            if (result?.status === 200) {
                dispatch({ type: "UPDATE_USER", payload: result.data });
            }
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    const deleteUser = async (user) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.delete(`/api/users/${user.id}`);
            dispatch({ type: "DELETE_USER", payload: result.data });
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    return (
        <UsersContext.Provider
            value={{
                users: state.users,
                fetchUsersList,
                addUser,
                updateUser,
                deleteUser,
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
