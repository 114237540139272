import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import styled from "styled-components";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";
import AnimatedElement from "../components/AnimatedElement";
import SectionHeader from "../components/SectionHeader";
import bandPhoto1 from "./../../media/bandphoto-1.jpg";
import bandPhoto2 from "./../../media/bandphoto-2.jpg";
import bandPhoto3 from "./../../media/bandphoto-3.jpg";
import { Carousel } from "react-carousel-minimal";

const Biography = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <AnimatedElement>
                    <SectionHeader>
                        CTHULUMINATI: PRINCIPAL MANIFESTO OF ORIGIN, DOCTRINES
                        AND ASPIRATIONS
                    </SectionHeader>
                </AnimatedElement>
                <AnimatedElement>
                    <ParagraphStyled>
                        Mid-January, 2015. An emergency executive meeting was
                        called, after unanticipated events within psychedelic
                        black ‘n roll ensemble Marquis necessitated the
                        occurrence of a substantial metamorphosis. This was the
                        gathering, during which a large cascade of future
                        occurrences -larger than possibly could have been
                        predicted at that time- would be set in motion. It was
                        also that precise juncture of initial conception when
                        the now revered name ‘Cthuluminati’ was uttered for the
                        first time, to later be recorded in the annals of
                        history. Shortly thereafter, Cthuluminati was formally
                        and unanimously christened as the next vessel to which
                        all involved parties would dedicate all the time, energy
                        and resources that they could possibly spare. New-found
                        perspectives have led to the implementation of extensive
                        internal reformation since then, putting the band back
                        into operation with the ambitious purpose of pushing
                        their limits of imagination and composition even further
                        than before. Meanwhile, novice devotees have started to
                        develop an appetite for the psychedelic spectacle that
                        they know will be bestowed upon them, while being
                        presented with a rare taste of the still mostly hidden
                        fruit of this eccentric group’s endeavours. The
                        opportunity of experiencing this oddity of a band in the
                        flesh has been offered to but a few, but still rumour
                        has spread almost naturally as a consequence. The
                        abundance of high praise and promising cosmic omens
                        seems to convey that even the Old Ones themselves have
                        given the project nothing but their tentacled blessings.
                        To the many unwitting souls who are not yet aware of
                        Cthuluminati’s emerging presence, know this: The worthy
                        listener will undoubtedly be able to hear the many
                        influences from a diversity of metallic species (black-,
                        stoner-, doom-, progressive, etc.), but will also notice
                        the distinct roles of added psychedelic effects and
                        vocals, as well as traces of the occasional excursion
                        into blues, jazz, psychedelic 70’s rock, and/or whatever
                        other musical concept was deemed appropriate for
                        inclusion during the design of this band’s repertoire.
                        All these elements are added in accordance with a
                        constant pursuit for a healthy balance between
                        eclecticism and coherence. Furthermore, Cthuluminati has
                        recently grown to encompass other ways of storytelling
                        as well, steadily transcending beyond the use of sound
                        as the sole source of creative output. Unique visual art
                        and illustrative background stories will add new
                        synergistic layers to the music, immersing the beholder
                        in an alternate universe filled with Lovecraftian
                        horrors, psychotic confusion, unspeakable conspiracies
                        and other dark mysteries throughout space and time.
                        Starting with the continuing expansion of this
                        psychedelic realm of weirdness, Cthuluminati ultimately
                        strives to evolve from a band into a functional
                        collective, creating an environment in which a number of
                        select artists -skilled in various different forms of
                        art- will help to develop new varieties of content that
                        you, dear reader, have not witnessed anywhere before.
                        For now, those struggling to control themselves in
                        violent anticipation of Cthuluminati’s eruption into the
                        world, should take note that only a little patience is
                        still required. Although the many pieces of the puzzle
                        currently are still being assembled in the shadows, the
                        first tale is to be fully revealed very soon. Consult
                        the band’s direct website or visit the band’s media
                        pages to receive the education you need, and get
                        involved in the plot…
                    </ParagraphStyled>
                </AnimatedElement>
                <Carousel
                    data={[
                        {
                            image: bandPhoto1,
                        },
                        {
                            image: bandPhoto2,
                        },
                        {
                            image: bandPhoto3,
                        },
                    ]}
                    time={2000}
                    width="850px"
                    height="500px"
                    radius="0px"
                    slideNumber={false}
                    captionPosition="bottom"
                    automatic={false}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="transparent"
                    slideImageFit="cover"
                    thumbnails={false}
                    thumbnailWidth="100px"
                    style={{
                        textAlign: "center",
                        maxWidth: "850px",
                        maxHeight: "500px",
                        margin: "40px auto",
                    }}
                />
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default Biography;
