import React, { useRef, useEffect, useContext, useLayoutEffect } from "react";
import styled from "styled-components";
import MainPageLayout from "../components/MainPageLayout";
import AnimatedElement from "../components/AnimatedElement";
import SectionHeader from "../components/SectionHeader";
import { ShowsContext } from "../context/ShowsContext";

const LoadingComponent = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

    div {
        display: inline-block;
        padding-top: 0.32rem;
        padding-bottom: 0.32rem;
        margin: 0 30px;
        line-height: inherit;
        white-space: nowrap;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.6;
        text-decoration: none;
        color: #fff9;
    }
`;

const CancelledLabel = styled.div`
    color: #941e1e;
    font-size: clamp(0.6rem, 3vw, 0.7rem);
    text-transform: uppercase;
`;

const ShowsOverviewStyled = styled.div`
    margin: 10vh 2vw 5vh 4vw;
    display: flex;
    flex-direction: column;
    width: min(26rem, 100%);
    height: fit-content;
`;

const ShowRowStyled = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1.5em;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: clamp(0.65rem, 3vw, 0.95rem);
    font-weight: 400;
    line-height: 1.8;
    text-decoration: none;
    color: #fff9;
`;

const ShowBottomRightElement = styled.div`
    display: flex;
    gap: 0.5em;
`;

const ShowRightElement = styled.div`
    width: 70%;
`;

const ShowLeftElement = styled.div`
    width: max(35%, 90px);
    padding-top: 2px;
    font-family: "Lucida Console", "Lucida Sans Typewriter", monaco,
        "Bitstream Vera Sans Mono", monospace;
`;

const ShowDateTop = styled.div`
    background: #d6dee138;
    color: #d6dee1;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding-right: 5px;
    border-radius: 10px;
`;

const ShowsOverview = () => {
    const myRef = useRef();
    const { shows, loading, error, fetchShowsList } = useContext(ShowsContext);
    const today = new Date();
    const futureGigs = shows?.filter((show) => new Date(show.date) >= today);
    const pastGigs = shows?.filter((show) => new Date(show.date) < today);

    useEffect(() => {
        if (shows?.length === 0) {
            fetchShowsList();
        }
    }, []);

    useLayoutEffect(() => {
        if (!loading) {
            setAnimationDelays();
        }
    }, [loading]);

    const setAnimationDelays = () => {
        const divs = myRef.current.children;
        for (let i = 0; i < divs.length; i++) {
            divs[i].style["animationDelay"] = `${i + 1}00ms`;
        }
    };

    const formatDate = (date) => {
        const dateToFormat = new Date(date);

        const optionsPart1 = {
            weekday: "short",
            day: "2-digit",
            month: "short",
        };

        const optionsPart2 = {
            year: "numeric",
        };

        const dateFormattedPt1 = new Intl.DateTimeFormat(
            "en-US",
            optionsPart1
        ).format(dateToFormat);
        const dateFormattedPt2 = new Intl.DateTimeFormat(
            "en-US",
            optionsPart2
        ).format(dateToFormat);

        return [dateFormattedPt1, dateFormattedPt2];
    };

    return loading ? (
        <LoadingComponent>
            <div>loading</div>
        </LoadingComponent>
    ) : (
        <MainPageLayout>
            <ShowsOverviewStyled ref={myRef}>
                {futureGigs.length > 0 && (
                    <AnimatedElement>
                        <SectionHeader>Coming shows</SectionHeader>
                    </AnimatedElement>
                )}
                {futureGigs.map((gig) => (
                    <AnimatedElement key={gig.id}>
                        <ShowRowStyled>
                            <ShowLeftElement>
                                <ShowDateTop>
                                    <div style={{ paddingLeft: "7px" }}>
                                        {formatDate(gig.date)[0]}
                                    </div>
                                </ShowDateTop>
                                <div>
                                    <div style={{ paddingLeft: "7px" }}>
                                        {formatDate(gig.date)[1]}
                                    </div>
                                </div>
                            </ShowLeftElement>
                            <ShowRightElement>
                                <div>{gig.title}</div>
                                <ShowBottomRightElement>
                                    <div>{gig.venue},</div>
                                    <div>{gig.city},</div>
                                    <div>{gig.country}</div>
                                </ShowBottomRightElement>
                                {gig?.cancelled ? (
                                    <CancelledLabel>cancelled</CancelledLabel>
                                ) : null}
                            </ShowRightElement>
                        </ShowRowStyled>
                    </AnimatedElement>
                ))}
                {pastGigs.length > 0 && (
                    <AnimatedElement>
                        <SectionHeader>Past shows</SectionHeader>
                    </AnimatedElement>
                )}
                {pastGigs?.map((gig) => (
                    <AnimatedElement key={gig.id}>
                        <ShowRowStyled>
                            <ShowLeftElement>
                                <ShowDateTop>
                                    <div style={{ paddingLeft: "7px" }}>
                                        {formatDate(gig.date)[0]}
                                    </div>
                                </ShowDateTop>
                                <div>
                                    <div style={{ paddingLeft: "7px" }}>
                                        {formatDate(gig.date)[1]}
                                    </div>
                                </div>
                            </ShowLeftElement>
                            <ShowRightElement>
                                <div>{gig.title}</div>
                                <ShowBottomRightElement>
                                    <div>{gig.venue},</div>
                                    <div>{gig.city},</div>
                                    <div>{gig.country}</div>
                                </ShowBottomRightElement>
                                {gig?.cancelled ? (
                                    <CancelledLabel>cancelled</CancelledLabel>
                                ) : null}
                            </ShowRightElement>
                        </ShowRowStyled>
                    </AnimatedElement>
                ))}
            </ShowsOverviewStyled>
        </MainPageLayout>
    );
};

export default ShowsOverview;
