
export const initialState = {
    storyFontSize: localStorage.getItem('settings.storyFontSize') ?? 'inherit',
}

export const settingsReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FONTSIZE":
        return {
            storyFontSize: action.payload
        }
 
      default:
        return state
    }
}
