import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { PageStyled } from "../App";
import { AccountContext } from "../../context/AccountContext";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { account, checkAdminPermission } = useContext(AccountContext);

    useEffect(() => {
        checkAdminPermission();
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!!account.loggedIn) {
                    return (
                        <PageStyled>
                            <Component {...props} {...rest} />
                        </PageStyled>
                    );
                }
                return (
                    <Redirect
                        to={{
                            pathname: "/cultmeister/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};
