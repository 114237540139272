import React, { useState, useEffect, useContext } from "react";
import { useLocation, withRouter, Link } from "react-router-dom";
import Header from "./Header";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { routes } from "./../routes";

const StyledLink = styled(Link)`
    display: inline-block;
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
    margin: 0 25px;
    line-height: inherit;
    white-space: nowrap;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: none;
    color: #fff9;
`;

const HeaderContainer = styled.div`
    position: absolute;
    overflow: overlay;
    width: 100%;
    z-index: 2;
`;

export const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: black;
    height: 100vh;
    z-index: 2;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    transform: ${({ showMobileMenu }) =>
        showMobileMenu ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 0.5s ease-in-out;

    @media (max-width: 700px) {
        width: 100%;
        margin-top: 5%;
        height: 100%;
    }

    @media (min-width: 700px) {
        display: none;
    }

    a {
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.5rem;
        color: white;
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 700px) {
            font-size: 1rem;
            text-align: center;
        }

        &:hover {
            color: grey;
        }
    }
`;

const AnimatedHeader = () => {
    const [showHeader, setShowHeader] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setShowHeader(![routes.main, "", "/"].includes(location?.pathname));
        setShowMobileMenu(false);
    }, [location]);

    return (
        <div>
            <StyledMenu showMobileMenu={showMobileMenu}>
                <StyledLink to={routes.main}>Main</StyledLink>
                <StyledLink to={routes.bio}>Bio</StyledLink>
                <StyledLink to={routes.shows}>Shows</StyledLink>
                <StyledLink to={routes.stories}>Stories</StyledLink>
                <StyledLink to={{ pathname: routes.store }} target="_blank">
                    Store
                </StyledLink>
            </StyledMenu>

            <HeaderContainer>
                <React.Fragment>
                    <CSSTransition
                        in={showHeader}
                        classNames="header"
                        timeout={2000}
                        unmountOnExit
                    >
                        <Header
                            showMobileMenu={showMobileMenu}
                            setShowMobileMenu={setShowMobileMenu}
                        />
                    </CSSTransition>
                </React.Fragment>
            </HeaderContainer>
        </div>
    );
};

export default withRouter(AnimatedHeader);
