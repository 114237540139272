import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";

const StoryAThinLine = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <StoryHeaderStyled>
                    <BackButton route={routes.reliqideus} />A Thin Line
                </StoryHeaderStyled>
                <StorySubHeaderStyled>
                    Thal, Austria, August 1983
                </StorySubHeaderStyled>
                <ParagraphStyled>
                    <em>
                        ‘That was a load of bullshit. I really can't understand
                        why you voluntarily want to listen to all that gibberish
                        about a creator that clearly doesn't exist.’{" "}
                    </em>
                    <br />
                    <em>"</em>Shut up. You know I don't go to church because I
                    believe in a God. I merely go there because I need to blend
                    into this village. I always used to go with my parents, and
                    I don't want to raise suspicion or have people coming by the
                    house asking why I don't come to church anymore."
                    <br />
                    <em>
                        ‘Well I still think it's a waste of time and fucking
                        boring. Next time please bring some narcotics or
                        alcohol. That should make it somewhat bearable.’
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam Baumgartner was driving home in his company van. His
                    house was in a remote part of the village. The nearest
                    neighbor was at least a mile away. He drove up the driveway
                    and parked his van in the garage. As he entered the house,
                    he heard the radio playing, which he always left on when he
                    went out.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        ‘Oh my god, not this song again. I’d really like to take
                        those ninety-nine red balloons and stick them up her ass
                        someday. ‘
                    </em>
                    <br />
                    "Something's wrong…" <br />
                    <em>
                        ‘You can say that again. If this kind of music is
                        getting airplay on the radio, this world is seriously
                        heading in the wrong direction.’
                    </em>
                    <br />
                    "No not that. It’s open. I know for sure I closed the door
                    to the basement. I always do."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    As Adam walked to the basement door, he saw a little girl
                    run into the kitchen. He ran after her. As he entered the
                    kitchen, he saw her slipping out to the courtyard. Furious,
                    he kicked open the door to the courtyard, which was the only
                    exit from it. <br />
                    "Where the hell do you think you're going?" <br />
                    <em>‘Kick her!’</em>
                    <br />
                    "How did you get out of the basement?!" <br />
                    <em>‘Kick her in the face!’</em>
                    <br />
                    "You've got nowhere to run..." <br />
                    <em>‘Come on! You’ve got to make her bleed for this!’</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    The little girl looked up at Adam in fear and shouted for
                    help. <br />
                    <em>‘Oh you've done it now, little bitch.’</em>
                    <br />
                    "No one is going to hear you here my child." <br />
                    <em>‘Please hurt her... ‘</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    The girl was trying to crawl away from Adam as he approached
                    her, but she was trapped like a mouse in a snake terrarium.
                    Adam caught her and hit her hard in the face, breaking her
                    left jaw bone. <br />
                    <em>‘Hallelujah, praise that cocksucker!’</em>
                    <br />
                </ParagraphStyled>
                <ParagraphStyled>
                    The little girl sat frightened in the corner of the
                    courtyard, heavily crying. Adam grabbed her by the hair and
                    dragged her back into the house. He pushed her through the
                    basement door and forced her down into the basement.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "How did you get out of the chains?!" Adam demanded. <br />
                    <em>‘I told you we should have put that one in a cage.’</em>
                    <br />
                    "Now I'm forced to put you in one of these." Adam pointed at
                    a small dog cage, and the girl winced the moment Adam raised
                    his hand. The girl did not respond. A piece of her jawbone
                    was sticking out of her cheek, and blood was running down
                    from it.
                    <br />
                    <em>
                        ‘Look at those ankles. They're so tiny, no chain is
                        going to hold her for very long, stupid!’
                    </em>
                    <br />
                    Adam took her arm and pushed her into the tiny dog cage. It
                    was just big enough for the little girl to sit up straight.
                    Adam closed the cage and turned around. <br />
                    "Anybody else got something to say about this?" <br />
                    He looked around and saw six-and-a-half pairs of little eyes
                    staring at him, scared to death, but no one made a sound.{" "}
                    <br />
                    <em>‘Oh please, not all at once.‘</em>
                    <br />
                    "If nobody tells me what happened, there will be no dinner
                    tonight, and you can divide the little water that's left
                    among you. Then we'll see in the morning if one of you feels
                    like talking. If not, we'll skip breakfast too…"
                    <br />
                    Adam was ready to head back up to the living room when a
                    little boy made a weeping sound. <br />
                    <em>‘Looks like we got a winner.’</em>
                    <br />
                    "You got something on your chest young man?" <br />
                    Shivering, the little boy started to talk. <br />
                    "Sh..sh..she used the butter from the sandwiches w-we had
                    for breakfast on her f-feet, and then she got out of the
                    chains.”
                    <br />
                    <em>‘The little tattler!’</em>
                    <br />
                    "Go on," Adam said. <br />
                    "Sh.. she got out and w-walked through the d-door. It was
                    open." the little boy continued. <br />
                    <em>‘Punish that little rat for being a tattle tale.’</em>
                    <br />
                    "Thank you for telling me that,” Adam said, “but I'll have
                    to punish you for being a snitch; I hate that." He spat the
                    little boy in the face. "Everybody will be having dinner
                    tonight except you, and I'll be having dinner with you all
                    so I can make sure you don't eat," Adam said, looking at the
                    shivering boy. <br />
                    Adam turned to leave. "And no bedtime story tonight, so you
                    can think about what happened, so it won't happen again." He
                    ascended the stairs and locked the basement door. <br />
                    <em>
                        ‘You stupid fuck, you left the door unlocked. What were
                        you thinking?’
                    </em>
                    <br />
                    "Maybe if you wouldn't talk so much, I could think more
                    clearly!"{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam sat down on the couch in the middle of the living room.
                    It was a large room and it was filled with mounted animals.
                    Heads hung on the walls, and some animals were standing on
                    the floor. Adam’s father had been a taxidermist, and when he
                    had died, Adam had decided to continue his father's work.
                    Adam was actually quite good at it, and though his father
                    had taught him everything there was to know about the art of
                    preparing and stuffing the skins of animals, he was better
                    than his father ever had been. Adam had always wanted to be
                    a scientist. As a kid he used to experiment on small
                    animals, just for fun and out of curiosity, to see how they
                    would react to certain liquids and powders. He finished
                    Science School with top grades, but when his father died he
                    wanted to continue the family business. His father had made
                    quite a name as a taxidermist all over the country and made
                    a lot of money, so he decided to follow in his father's
                    footsteps.
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam tried to sleep a little. He closed his eyes, but
                    immediately opened them again. <br />
                    "Oh hell I can't sleep with those children screaming in my
                    basement." <br />
                    <em>
                        ‘What are you talking about? You made the basement
                        soundproof and they're not screaming. We made it
                        perfectly clear with that broomstick that if they scream
                        there will be repercussions. Jesus, it was really funny
                        when you put that thing in that little boy's....’
                    </em>
                    <br />
                    "Shut up man! You really don't hear those annoying screams
                    coming from the basement? It's driving me crazy!" <br />
                    <em>
                        ‘Oh man, you're really losing it, my pally pal. You know
                        what you need to do. Just take a little of the good
                        stuff. You know it's the perfect cure!’
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam got up and walked to a little cabinet in the corner of
                    the room, took out a small wooden box, and sat back down on
                    the couch. He opened the box and took out a rolling paper,
                    filter, and some tobacco. After putting some tobacco in the
                    paper, he took a little bottle with a transparent liquid
                    inside out of the small box and sprinkled some drops onto
                    the tobacco. He rolled the cigarette, lit it, and took a few
                    hits, which he inhaled deeply. He finished the cigarette and
                    laid his head on the edge of the couch. After just a few
                    minutes Adam felt lighter, and it seemed that the screaming
                    disappeared. He closed his eyes. <br />
                    "Oh thank god, it's gone." <br />
                    <em>‘See I told you. Enjoy the silence my friend.’</em>
                    <br />
                    "Yes you were right, as always. Thank you."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "Pork chops!" <br />
                    "What?" <br />
                    "Pooooork chop day!" <br />
                    "What the hell are you talking about?" <br />
                    Adam opened his eyes and was surprised to find himself
                    sitting at a large table. Everything looked so white it hurt
                    Adam's eyes. Next to him sat a large, bald man. The man wore
                    a Superman t- shirt that was so small that Adam could see
                    the man’s large belly sticking out underneath it. <br />
                    "Oh boy, oh boy, oh boy, it's pork chop day,” the large man
                    said with a big smile on his face. “Come on Adam, you know
                    Wednesday is pork chop day." <br />
                    Adam looked around and saw more people sitting around the
                    table. They all looked like crazy people to him.{" "}
                    <em>‘This is a nuthouse’</em>, Adam thought. <br />
                    "Poooooooork chops," the big, bald man screamed in Adam's
                    ear. <br />
                    Adam grabbed the man's throat and screamed, "What the fuck
                    is this?! Where the fuck am I?! What the fuck is going
                    on?!!" <br />
                    Adam squeezed his throat with so much force that the man's
                    head turned red as he gasped for air. <br />
                    "We got a stubborn one," a man who looked like a nurse said,
                    and immediately three other nurses ran at Adam and grabbed
                    him. <br />
                    "Let go of me!" Adam shouted as he struggled to get free.{" "}
                    <br />
                    "Get the sedative," one of the nurses said. <br />
                    The men forced Adam down onto a stretcher, tied him up and,
                    before Adam knew what was going on, gave him an injection.
                    Within seconds Adam lost consciousness and everything went
                    black.
                </ParagraphStyled>
                <ParagraphStyled>
                    When Adam woke up he was sitting on his couch again and he
                    was sweating like a horse. <br />
                    "Jesus what was that? It felt so real!" <br />
                    <em>‘What do you mean?’</em>
                    <br />
                    "Nothing. I just had a nightmare. Shit, look at the time. I
                    have to feed those brats downstairs. What should I give them
                    this time?" <br />
                    <em>‘How about some pork chops? Heh…’</em>
                    <br />
                    "What? Why do you say that?" <br />
                    <em>
                        ‘Ah come on man, just kidding. We're not going to waste
                        perfectly good meat on those children.’
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam went into the kitchen, took a large pan out of one of
                    the cupboards and started making soup with powder out of a
                    large container. He took some bread and the large pan
                    downstairs into the basement. He put the soup into small
                    bowls and divided them between the children. He took a bowl
                    to the boy who had told on the little runaway girl. He
                    stopped in front of him and threw the bowl against the wall
                    right beside the little boy's head. The bowl shattered into
                    pieces and some of the boiling hot soup burned the boy's
                    face. <em>‘Serves him right!’ </em>
                    <br />
                    "I'm not done with you yet, little rat!" Adam said with a
                    sneer on his face. "Maybe I'll let you starve to death. I
                    got enough of you kids anyway." <br />
                    <em>
                        ‘Oh that's harsh. That's why I like you. You know that,
                        don't you?’
                    </em>
                    <br />
                    The little boy did not dare to make a sound and turned away
                    his head so that Adam couldn't see him cry. Adam turned to
                    the little girl in the dog cage, looked at her, and said,
                    "And for you my little princess, I got a little surprise."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    He took her out of the cage and dragged her to the end of
                    the basement. They stopped at a workbench on which sat a lot
                    of bottles, tubes, scales, and petri dishes. It looked like
                    a small laboratory. In some of the bottles there were funny
                    looking liquids and there was a strange aroma hanging over
                    the bench. Adam took one of the bottles—it had a brownish
                    liquid in it—and put it over a Bunsen burner. The liquid
                    immediately started to foam and turned a dark red. After a
                    few seconds, Adam took a syringe out of a little drawer and
                    filled it with the prepared liquid.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "This will hurt like hell, I suppose," Adam said with a
                    smile on his face and jammed the syringe in the little girls
                    broken jaw. The girl started screaming like a pig at
                    slaughter. <br />
                    <em>‘Bullseye! Nice shot!’</em>
                    <br />
                    "Stop whining like a little bitch. If my formula is correct,
                    you won't feel a thing after a few minutes, and your pretty
                    little face will heal after a few more shots. If this works
                    I will be famous for inventing a miracle cure. Now get back
                    into your cage!" <br />
                    Adam put the girl, who was still screaming relentlessly,
                    back into the little cage and locked it. <br />
                    He turned around and said, "I know I said no bedtime story,
                    but because the majority of you have been good, I don't want
                    to take away the little joys you have left in your pathetic
                    little lives. I'm going to read one of my favorite books to
                    you." <br />
                    Adam took a book from a shelf and sat down. "This is one of
                    my favorite children’s stories."
                    <br />
                    He started to read:{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        “Annie is curious, a curious girl she is <br />
                        When asking the clerk, he responds with a hiss <br />
                        ‘Where are we from and of what are we made?’ <br />
                        ‘We are from god, child, don’t be afraid’ <br />
                        Annie is curious, that’s how she’s made <br />
                        But the clerk’s odd answer made Annie afraid <br />
                        ‘What should I fear, if God weren’t there?’ <br />
                        ‘you should fear evil, it’s real so beware..’ <br />
                        Annie is curious, but she does regret <br />
                        For the clerk has changed since the last thing he said{" "}
                        <br />
                        ‘Am I safe father? I feel strange in my head’ <br />
                        ‘No you are not and soon you be dead’ <br />
                        Annie was curious, but not anymore <br />
                        Now she will shut up, shut up you whore! <br />
                        This is what happens when toddlers ask why <br />
                        They will get hurt, and slowly they’ll die”{" "}
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    After reading, Adam put away the book and got up. "And
                    you’ll make no sound if you know what's good for you. Now
                    sleep well my little angels. I'll be back here tomorrow
                    morning."
                </ParagraphStyled>
                <ParagraphStyled>
                    He went upstairs, locked the basement door, took his little
                    wooden box out of the small cabinet and walked up to the
                    second floor into his bedroom. His bedroom was a small room
                    without windows. It used to be a storeroom, but after his
                    mother died he made it his bedroom. Somehow, this windowless
                    room gave Adam a strange feeling of safety and familiarity.
                    "Pffff what a day. I just want to go to sleep. Tomorrow is
                    going to be a long day. I have to deliver that stuffed boar
                    to one of my best clients near Vienna." <br />
                    <em>
                        ‘Yes indeed, but first it's relaxation time. Look, you
                        got half a bottle of schnapps left. That looks like a
                        good start.’
                    </em>
                    <br />
                    Adam took the bottle and drank some of it, put the bottle on
                    a small table and started rolling one of his magical
                    cigarettes. After he finished smoking the cigarette, he had
                    some more schnapps and lay down on his bed. <br />
                    <em>‘Ah, that's better. Now it's time to relax.’</em>
                    <br />
                    After a while Adam fell into a deep sleep.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Adam, wake up. Adam, it's time to wake up!" <br />
                    Adam opened his eyes and saw that he was strapped to a
                    hospital bed. He was in a small padded room. <br />
                    "Good morning Adam, I hope you're feeling better now," a man
                    who looked like a doctor said to him. The man was sitting on
                    a chair next to the bed. <br />'<em>Oh no, not again</em>',
                    Adam thought. Maybe I should try to do fewer drugs. <br />
                    "Whh.. whhe.. where the hell am I? Who are you?" Adam asked,
                    disorientated. <br />
                    "Oh come on Adam, not this again… I thought we had left this
                    point behind months ago," the doctor said. "But fine, I will
                    play along with this game one more time. My name is Doctor
                    Schultz. You are in a psychiatric hospital in Engelsdorf.
                    You have been our guest for quite a few years now, and
                    you're strapped to this bed because you nearly killed poor
                    Walter. You know Walter is harmless and would never hurt
                    anyone if they don't agitate him. What were you thinking
                    Adam?" <br />
                    "What?! No, this is not happening…” Adam said. “This feels
                    so real. This is not possible! Release me from this bed.
                    Release me from this horrible place!!" Adam started
                    screaming, "Let me go god dammit! Let me goooo! Let me go!
                    Let me go now!! I will hurt you if you don't let me go!!"
                    Adam struggled to set himself free and screamed like a
                    madman. <br />
                    "Nurse! nurse!" the doctor shouted. "We need another shot to
                    calm him down." Two big men came into the little room and
                    gave Adam another injection. <br />
                    "Not real, not real, not re...," Adam murmured, and then
                    everything went black again.
                </ParagraphStyled>
                <ParagraphStyled>
                    With a jolt, Adam sat up straight in his bed. He felt cold
                    sweat running down his back, and his mouth felt as dry as
                    sandpaper. He took the bottle from the table and started
                    drinking like someone who has been in the desert for two
                    days without water. Immediately he regretted his actions,
                    realizing it was the bottle of schnapps he was emptying and
                    spat the alcohol out like a professional fire breather.{" "}
                    <br />
                    <em>‘Hahahaha, pussy!’</em>
                    <br />
                    "Yeah sure, just laugh. I'm having nightmares that feel
                    fucking real lately, so get off my back." <br />
                    <em>‘Well excuse me, princess.’</em>
                    <br />
                    Adam looked at the time. It was six in the morning. He had
                    an appointment with his client in a few hours and it was a
                    long drive, so he decided to get up, wash himself, and take
                    a quick look at the children before leaving for work. He
                    left his room and entered the bathroom on the other side of
                    the hallway. He washed his face with cold water at the sink
                    and when he looked in the mirror he suddenly saw himself
                    lying strapped on the bed in the padded room he had dreamed
                    about. Agitatedly, he turned around but all he saw was the
                    rusty old bathtub and the toilet. He looked in the mirror
                    again and everything was back to normal. Anxious, Adam went
                    down to the kitchen to get some bread and water and took it
                    down into the basement. As he entered the basement he heard
                    some of the children sobbing and crying. <br />
                    <em>
                        ‘Pffff, please promise me you will never have children
                        yourself. This crying and nagging would drive me crazy.’
                    </em>
                    <br />
                    "Why are you crying my loves? Didn't you sleep well?" <br />
                    One of the kids pointed at something behind Adam. Adam
                    turned around and looked at the little girl in the dog cage.
                    She was sitting motionless in a strange position. Adam
                    walked to her. He looked at her face and saw that the left
                    side of her face was swollen like a balloon, her left eye
                    was gone, and a brownish pus was running out of her left
                    nostril. <br />
                    <em>
                        ‘Ehhhm, I'm not a doctor or scientist but it seems to me
                        your calculations of the formula were a bit off,
                        Einstein. That looks like a dead girl to me.’
                    </em>
                    <br />
                    "Hmmm that's too bad," Adam said. "Looks like I have to
                    recalculate this formula. Damn, I was so close. Oh well,
                    I’ll just have to fetch me another subject so I can continue
                    my tests. Now you be good kids. I will be gone for the day,
                    so I hope you won't do anything stupid, like trying to
                    escape, or you might just end up like her." He pointed at
                    the dead girl in the cage.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam gave the children the bread and water, took the girl
                    out of the cage, and took her upstairs. He took her to the
                    courtyard and buried her beneath the big compost heap
                    alongside his other failures. Adam walked into his workshop,
                    which was next to the garage, grabbed a stuffed boar he had
                    prepared for one of his customer, and put it in the back of
                    the van. He closed the back doors and walked to the front to
                    enter the van. <br />
                    <em>‘Hellooo?!’</em>
                    <br />
                    "What?" <br />
                    <em>
                        ‘Fuck me, two times in twenty four hours. Really, do I
                        have to do all of the thinking? What's wrong with you
                        the last few days?‘
                    </em>
                    <br />
                    "What? Could you be more specific?" <br />
                    <em>
                        ‘Basement door, remember? Oh no, wait, you didn't, dumb
                        amnesiac!’
                    </em>
                    <br />
                    "Ah fuck, not again. I don't know. It seems that I can't
                    think straight lately." <br />
                    Adam went back to the basement, locked the door, and got in
                    his van.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    After a three-hour drive, Adam arrived at his customer's
                    house just outside a small village and delivered his work of
                    art. The customer was extremely satisfied, which didn't
                    surprise Adam at all because his customers always were. Adam
                    left the estate and drove back to the village. It was a
                    village at the foot of a mountain so there was only one
                    exit, that went through a large forest. Halfway into the
                    forest Adam saw a little boy on a bike ahead. <br />‘
                    <em>
                        Ah sweet, this one is too easy. Come on, it's harvesting
                        time. You know what to do!’
                    </em>
                    <br />
                    Adam checked his side mirrors and the road in front of him,
                    but he and the boy were the only ones in the forest. He took
                    a cloth and a bottle with a white substance he had made
                    himself out of the glove compartment, sped up, and cut his
                    van in front of the boy. Adam jumped out of the van, took
                    the cloth he had already soaked in the white liquid, and ran
                    at the boy. The boy was lying on the road next to his bike,
                    he looked unconscious but Adam put the cloth on his nose and
                    mouth just to be sure. He put the boy in the back of the van
                    and closed the doors. <br />
                    <em>
                        ‘The bike dumbass, take the bike! You don't want people
                        to find a bike without a child. That would make them
                        search immediately!’
                    </em>
                    <br />
                    "Oh fuck, you're right!" <br />
                    Adam put the bike in the back with the boy, closed the doors
                    and drove away casually, heading home.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Dusk was already falling as Adam arrived at his house. He
                    parked the car in the garage and closed the doors. He walked
                    to the back of the van and took out the boy, who was still
                    unconscious. <br />
                    <em>
                        ‘Looks like you're not a total dimwit. The anesthetic
                        you created seems to work just fine.’
                    </em>
                    <br />
                    Adam took the boy into the basement. As he opened the
                    basement door he saw the little children cower. Some of them
                    were crying. <br />
                    <em>
                        ‘Oh fuck, here come the waterworks again. Bloody hell,
                        that's so fucking annoying. Maybe you should cut out
                        their vocal cords so that we can have some peace and
                        quiet for once.’
                    </em>
                    <br />
                    Adam put the sedated boy on the floor against the wall and
                    chained him, then turned around to the other little boy
                    whose face was now bright red from the burning of the hot
                    soup and kicked him hard in the side. The boy flinched but
                    did not dare to make a sound. <br />
                    "Hello. I do hope you all missed me," Adam said. "I got you
                    all a new friend to play with. I don't know his name and I
                    couldn't care less. You make sure you make him feel welcome
                    here. I see you still have some water and bread left so you
                    will survive until tomorrow. Good. Now I can concentrate on
                    fixing my formula."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam went to his small laboratory and the following hours he
                    worked on his potion. He took fluids, added powders, mixed
                    and heated different things. <br />
                    "Well I think this should do it," he said after a while, and
                    he took a bottle from a small burner. "I think I solved the
                    problem, but there is only one way to know for sure... I
                    just have to test it on one of you. Mmmmm who will it be?"
                    He looked around, his lip curling. <br />
                    <em>
                        ‘Ooh, ooh, I know. I know a good one. Please let me
                        pick.‘
                    </em>
                    <br />
                    "Now let me see," Adam said, walking straight at the boy
                    with the burned face. "Eeny meeny miny.. oh who am I
                    kidding?" He grabbed the new boy by the ankle and dragged
                    him towards his work bench. The boy struggled but was no
                    match for Adam's strength. <br />
                    "You know what this is?" Adam asked the boy. "This is a
                    vise. I'm going to put your little hand in there and I'm
                    going to squash it and then I'll inject you with my magic
                    cure to see if it works. I'm telling you this so you can get
                    really afraid now and therefore it will hurt more. Isn't
                    that nice?" <br />
                    Adam took the little boy's hand, put it in the vise and
                    started turning the handle. The boy started screaming like a
                    fifteen-year-old girl at a Beatles concert. <br />
                    <em>‘One more turn. One more turn. Go, Adam!’</em>
                    <br />
                    "This should do it," Adam said, releasing the handle. As he
                    took the boy's hand, some of the skin came loose, and it was
                    soaked in blood. He took a syringe and filled it with the
                    newly created potion. "You know the only way to inject this
                    correctly is through the tip of your finger, don't you?"
                    Adam said, but the boy was in a shock and didn't respond
                    anymore. <br />
                    <em>
                        ‘Mmmm, too bad, no reaction anymore. Let’s see what
                        happens if you give him the injection.’
                    </em>
                    <br />
                    Adam took one of the boy's fingers and put the syringe into
                    the tip of it. The boy screamed like hell and then faded.{" "}
                    <br />
                    <em>
                        ‘Children nowadays. Can't handle much pain now, can
                        they?’
                    </em>
                    <br />
                    Adam bandaged the boy's hand, carried him to his chains, and
                    chained him. <br />
                    "It's late, so no more bedtime story tonight. Sleep well my
                    little guinea piglets." <br />
                    <em>
                        ‘Oink, oink, oink. Hehehe. Don't forget to lock the
                        door, please.’
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    He went upstairs, closed the basement door and sat down on
                    the couch in the living room. <br />
                    <em>
                        ‘Alright, that was entertaining. Now it's time to take
                        the good stuff!’
                    </em>
                    <br />
                    "No, not tonight. I just want to watch some TV." <br />
                    Adam got up, made himself some coffee and turned on the TV.{" "}
                    <br />
                    <em>‘Coffee, really? What's wrong with you?’</em>
                    <br />
                    "Let it go ok. I just want to relax and I don't want to go
                    to sleep yet." <br />
                    <em>
                        ‘Oh my god, you’re so boring. Fine, do as you please.
                        I'm out of here. I hope you're more sensible tomorrow,
                        wanker!’
                    </em>
                    <br />
                    Adam watched TV for hours and drank a lot of coffee. He just
                    wanted to stay awake because somehow he dreaded what could
                    happen if he fell asleep, but no matter how hard he tried
                    and how much coffee he drank, the tiredness got the best of
                    him and eventually he fell into a deep sleep.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam woke with a fright, his chest tight, his hart racing,
                    his breathing fast and uneven. He tried to get up but it was
                    not possible. He realized he was back in the padded room and
                    tied to the bed. <br />
                    "Hello?" Adam screamed. "Anybody there? Helloooo?!" He
                    listened, but heard no reaction. He tried to free himself
                    but it was no use.{" "}
                    <em>
                        ‘If I just can get back to sleep, I will be back home
                        and all will be fine’
                    </em>
                    , he thought. The next hour he tried to sleep but he had the
                    feeling the coffee he had drunk on the couch was still
                    working and he was wide awake. "Hello, could someone please
                    give me some sleeping pills?!" he shouted and banged his
                    head repeatedly on his pillow, hoping it would knock him
                    out, but it was too soft and didn't even hurt him. That
                    moment he heard the lock turning, and Doctor Schultz entered
                    the room.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "Good morning Adam," the doctor said. "I hope you are
                    feeling better." <br />
                    "I would, if you gave me some sedative," Adam said. <br />
                    The doctor smiled. "Now, now, Adam I think you had enough of
                    those. Maybe it's better you stayed sober for a while so you
                    can think about the things you've done lately." <br />
                    "I don't want to think about that!” Adam shouted. “I want to
                    go to sleep! I want to leave this fucking hell!” <br />
                    The doctor only smiled and shook his head. <br />
                    Maybe if I provoke him, he will call his nurses and they
                    will give me some kind of sleeping drug, Adam thought. He
                    shouted, "Just release me asshole so I can kick you so hard
                    you'll end up on a different latitude, or come closer so I
                    can bite your ear off!" <br />
                    The doctor started laughing hard. "Come on Adam, you're not
                    the black knight. I'm going to leave you be and I will check
                    on you tonight, hoping you will be more sensible.” <br />
                    He left the room and Adam was all alone again.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    The rest of the day was pure torture for Adam, he tried to
                    fall asleep by counting sheep, reliving his school lessons
                    and thinking about the church sermons he had attended, but
                    nothing seemed to work. <br />
                    Then, early in the evening after lots of agonizing hours of
                    thinking and freaking out, he finally fell asleep.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam opened his eyes and, to his relief, he was back in his
                    living room, sitting on his couch, the TV still on and a cup
                    of cold coffee on the table in front of him. <br />
                    <em>
                        ‘Good morning prince boring. How was your date with
                        sobriety last night?’
                    </em>
                    <br />
                    "Thank god, I never thought I would be so glad to hear your
                    annoying voice!" <br />
                    <em>
                        ‘Good to know that I'm appreciated. Love you too
                        honeybun. So what's the schedule today?’
                    </em>
                    <br />
                    "I don't know yet. First I need a cup of coffee and some
                    food." <br />
                    Adam made himself some breakfast and sat back down on the
                    couch. <br />
                    <em>
                        ‘There must be something we can do with those kids
                        today!’
                    </em>
                    <br />
                    "I'm going to give them some breakfast, check the little
                    boy's hand and then take the day off. I don't feel like
                    doing much today." <br />
                    <em>
                        ‘Sounds like a plan. And then just take some drugs so we
                        can relax for the day.’
                    </em>
                    <br />
                    "Yeah sure, but not too much. I want to stay straight in the
                    head a little bit."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    After finishing his breakfast, Adam took some food and water
                    down into the basement. The children were awake and quiet.{" "}
                    <br />
                    <em>
                        ‘Well that's good, some peace and quiet for a change.
                        Maybe they are finally learning that it's better for
                        their health if they keep their little mouths shut.
                        Let's see how that little fucker's hand is doing.’
                    </em>
                    <br />
                    Adam gave the children food and water and walked to the
                    little boy with the bandaged hand. <br />
                    The boy was awake but did not react. His eyes looked empty;
                    he just stared into nothingness. Adam poked the boy's nose
                    but there still was no reaction. He took the boy's hand and
                    slowly removed the bandage. Surprisingly, the hand looked
                    fine: only some dried up blood, but the wounds seemed
                    healed. <br />
                    <em>
                        ‘Good job, maestro! It looks like your cure is working.’
                    </em>
                    <br />
                    Adam felt ecstatic and took the boy's hand in his hands. In
                    that moment, the hand started to crumble and half of it
                    disintegrated instantly. The boy started to wail like a
                    police car siren. Quickly Adam took a fresh bandage and put
                    it around the boy's hand. The brat fainted. <br />
                    "Nooooo! Fuck, fuck, fuck!" Adam shouted, and he went back
                    upstairs. <br />
                    <em>‘Ahhh, so close. Oh well, better luck next time.’</em>
                    <br />
                    Adam sat down on the couch, took out some paper and began
                    writing down formulas and equations to find out what he had
                    done wrong.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    After a while, the doorbell rang. Adam was surprised; hardly
                    anybody ever came by his house. He got up and checked the
                    basement door. It was locked. <br />
                    <em>
                        ‘Good thing you made the basement soundproof last
                        summer.’
                    </em>
                    <br />
                    He walked to a little window beside the front door to check
                    who was there. <br />
                    <em>
                        ‘Wow, look at that. That must be the most beautiful
                        thing I've ever seen. That one would be excellent for
                        some fun.’
                    </em>
                    <br />
                    "No way. You know the rules. No one from the village!"{" "}
                    <br />
                    <em>
                        ‘Ahh, come on. Let's have some fun. You know you want
                        to. It's been, Jesus, I don't even remember the last
                        time we had a party. Fuck the rules.’
                    </em>
                    <br />
                    Adam opened the door. <br />
                    "Good day sir, I'm with the local football club and I'm
                    selling lottery tickets. With the money we make we can buy
                    new outfits and balls. You can win amazing prizes." It was a
                    little boy. He had shiny blond hair and the most beautiful
                    pair of green eyes Adam had ever seen. <br />
                    <em>
                        ‘Please tell me this boy is one of the prizes. Let's buy
                        all the tickets he has on him. Look at that skin. It
                        looks as soft as a baby's bottom. Excellent!’
                    </em>
                    <br />
                    "Aren't you one of the Wagner boys?" Adam asked. <br />
                    "Yes sir I am. I'm the youngest of the family," the boy said
                    as he took out the lottery tickets. <br />
                    <em>‘Grab him!’</em>
                    <br />
                    Adam examined the boy. "Yes, little Markus, isn't it? I
                    haven't seen you in a long time. You have grown quite a bit
                    since the last time I saw you." <br />
                    At this, the boy beamed with delight. "That's right. I will
                    be six years old in two weeks," he said proudly.
                    <br />
                    <em>
                        ‘We'll see about that. Hehehe. Come on grab the boy and
                        let's have some fun!’
                    </em>
                    <br />
                    "How much are the tickets?" Adam asked.
                    <br />
                    "One ticket is twenty Schilling, but if you buy five they
                    are...” Before Markus could finish his sentence, Adam had
                    grabbed the boy and pulled him into the house.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam closed the door and dragged the boy directly upstairs
                    to his bedroom. Little Markus struggled to get free and
                    tried to kick Adam, but the boy was powerless against Adam's
                    strong grip. <br />
                    <em>‘Ah, a feisty one. This could be fun.’</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    The next hour was nothing short of a living hell for little
                    Markus, and he did not get out of it unscathed. Adam gave
                    the boy some new and painful openings. After Adam was done
                    he untied the little boy, who was apathetic. He took the boy
                    downstairs into the basement and brought him to his little
                    laboratory. <br />
                    <em>
                        ‘That's right, patch him up. I know we will want to
                        party with that one again sometime soon.’
                    </em>
                    <br />
                    Adam started to disinfect the boy's wounds. He had to stitch
                    some of them. He did it without any anesthesia. This was too
                    much for Markus, so his mind decided to take a break and he
                    passed out. He put Markus into one of the bigger dog cages
                    that were standing in the basement. Adam saw the little boy
                    with the bandaged hand staring at Markus and shouted, "What
                    are you looking at? You should be glad you're butt ugly, or
                    else this could have been you!" He went back upstairs, but
                    not without kicking the bandaged boy in the chest first.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        ‘Come on, let's do drugs. No better time to get high
                        than after having a party like this.’
                    </em>
                    <br />
                    "No time for that now. I need to know what's wrong with the
                    formula. I'm so close. I know I am." <br />
                    <em>
                        ‘Sometimes you are such a party pooper, you know that?
                        Fine bookworm. Go and work on your life-saving formula.
                        I'm out of here. See you tomorrow, and I hope you'll be
                        into having a party then.’{" "}
                    </em>
                    <br />
                    "Yes be gone. I could use some silence while I'm working."{" "}
                    <br />
                    Adam got himself some coffee and started working on his
                    formula. He worked on it all day and the following night.
                    Before he knew it, it was morning.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>‘Good morning professor. How's life?’</em>
                    <br />
                    "It sucks, alright. I've been working all night and still I
                    don't have the answer to the problem." <br />
                    <em>
                        ‘You know what, just leave it for now. Just feed those
                        kids, leave them be for today, have some fun, get high
                        and relax.’
                    </em>
                    <br />
                    Adam did not argue with that. He brought the children food
                    and water, went back upstairs, headed for the cabinet, took
                    out his little box, and prepared his medicine. He smoked it
                    and sat back to relax. Feeling tired from all the hard work
                    he fell asleep within ten minutes. <br />
                    "Adam, wake up. Adam, wake up and listen to me." <br />
                    <em>‘Here we go again’</em>, Adam thought, and expected to
                    wake up in the loony bin. He opened his eyes, and to his
                    surprise he was still sitting on the couch in his house.{" "}
                    <br />
                    "You got to listen to me, Adam." <br />
                    He looked around in fright, because he didn't know where the
                    voice was coming from. <br />
                    "Up here, you filthy pederast." <br />
                    Adam looked up and saw a stuffed deer head looking straight
                    at him. <br />
                    "You're not real,” Adam said. “It's because of the drugs.
                    You're not talking to me.” <br />
                    "Not real," the deer head said, laughing. "It seems to me
                    you're not quite capable of judging what is real or not,
                    lately. This world feels real, the nuthouse feels real and
                    you can't deny this, right now, feels real too, doesn't it?
                    Now listen, you've gone too far this time. It's bad enough
                    you took those poor children for your lame excuse for
                    experimenting; taking a local boy for your sick pleasures
                    was a step too far." <br />
                    "Shut up! Shut the fuck up!!" Adam screamed. <br />
                    "I'm not shutting up," the deer head said in an eerie voice.
                    "They'll hang you for this, mark my words. You finally blew
                    it. They will come for you soon. Now go to sleep again."
                    Adam was dumbstruck, looked at the grinning deer head, and
                    passed out.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam opened his eyes and saw he was back in the padded room,
                    tied to the bed. The door opened and the doctor entered.{" "}
                    <br />
                    "Well Adam, I think I gave you enough time to think about
                    your actions. What do you have to say for yourself?" <br />
                    Adam, still confused, not knowing what was real anymore,
                    thought about it, and a kind of acceptance came over him.
                    Not wanting to remain tied to the bed anymore, he said, "I'm
                    really sorry for what I did. I didn't want to hurt anybody.
                    I don't know what came over me. I promise it will never
                    happen again.” <br />
                    Doctor Schultz looked at him. "You know Adam, I believe you,
                    and you've been in solitary long enough if you ask me. If I
                    let you go back to the others, you’ve got to behave. You
                    hear me?"
                    <br />
                    "I will, I promise," Adam said. <br />
                    "Good. Guys, release him and escort him back to the other
                    patients," the doctor said to two nurses. Relieved, Adam
                    walked with the nurses to the living area of the hospital.
                    As he entered the room, people stared at him. He looked
                    around and stood there for a while, wondering what to do.
                    Then, from the corner of his eye, he saw a large man
                    storming at him. "Nobody fucks with Superman!!" it was
                    Walter, the big bald guy Adam had tried to strangle to
                    death. "I'll kill you for that!!" Walter screamed, and he
                    stabbed Adam with a pen several times. Adam fell to the
                    floor and saw two nurses detaining Walter just before he
                    passed out.
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam gasped for air and saw he was back on the couch in his
                    living room. He looked around and up to the dear head. It
                    did not move or talk. <br />
                    <em>‘Hello sunshine. Wazzzuuuup?’</em>
                    <br />
                    "Not now ok. Just be quiet for once." <br />
                    <em>‘Grumpy, grumpy. Had a bad trip? ‘</em>
                    <br />
                    "That would be the understatement of the year." <br />
                    The doorbell rang. <br />
                    <em>
                        ‘Really? Two in one day? Let's take a look. Maybe it's
                        another of those sweet little boys. We can grab him too
                        and have ourselves some more fun.’
                    </em>
                    <br />
                    Adam got up and looked outside through little window beside
                    the front door. <br />
                    <em>‘Ahh shit! It's the pigs!! Don't open the door!’</em>
                    <br />
                    "Open the door please, mister Baumgartner!” one of the
                    policemen said. “We know you're home, we can see you looking
                    through the window.” <br />
                    <em>‘Aaaaww, dumb fuck!’</em>
                    <br />
                    Adam had no choice but to open the door. <br />
                    "Good day sir,” another one of the men said. “Have you seen
                    this boy? He's been missing for day. He was selling lottery
                    tickets and told his parents he would be home within an
                    hour, but he never came home and they got worried." He
                    showed Adam a picture. It was little Markus. <br />
                    "No, I haven't seen him," Adam said. <br />
                    "Do you mind us coming inside? The next door neighbor told
                    us he bought lottery tickets and the boy told him he would
                    be going to your house next,” the policeman said. <br />
                    "I do mind," Adam said. "I haven't seen him, so you have no
                    business coming into my house." <br />
                    "I still would like to look around inside your house,” the
                    policeman said. <br />
                    "Well, you’d better get a search warrant then," Adam said,
                    shutting the door. <br />
                    "Oh, we will sir. People with nothing to hide don't react
                    like that. We'll be back soon with the warrant." <br />
                    The policemen walked back to their car and drove off.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        ‘Shit, what should we do now?! They will come back and
                        they will find the children...’
                    </em>
                    <br />
                    "Yes they will. I'm going to run. There is no other option.
                    But what to do with the children?" <br />
                    <em>
                        ‘Well, taking them with us is not an option, but we
                        cannot let them live either, you know that. ‘
                    </em>
                    <br />
                    Adam ran into the kitchen, got a bottle of water and went
                    downstairs into the basement. He walked to his workbench and
                    poured some anesthetic liquid into the bottle of water. He
                    put the water in some cups and gave them to the children.{" "}
                    <br />
                    "Drink it or I will give you more pain than you can handle,"
                    Adam said, and the children drank the water without
                    hesitating. Markus was awake and he also drank the water.
                    Only the bandaged boy did not get any water from Adam. One
                    by one the children fell asleep. <br />
                    <em>‘Great, this is how Goebbels must have felt.’</em>
                    <br />
                    Adam went upstairs to the garage to get a jerry can with
                    petrol in it. He went back to the basement and started
                    pouring the petrol over the children. The bandaged boy
                    looked at Adam in fright. <br />
                    "The only reason I drugged the other kids is to watch you
                    suffer more," Adam said as he poured the petrol over the
                    boy. He took a candle and lit it. He threw the candle into
                    the petrol, which had spread all over the basement floor.
                    The children immediately began to burn, and the bandaged boy
                    started screaming and crying. <br />
                    <em>‘Good boy. Scream like a pig!’</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam went back upstairs and started pouring petrol all over
                    the first floor, lit it and ran to the garage. He started
                    his van and opened the garage door. The house was catching
                    fire rapidly. <br />
                    "Mister Baumgartner! Where are you going and why is your
                    house on fire?!" It was one of the police men. "We surveyed
                    your house for a while. Now put your hands up! You're under
                    arrest! We found a lottery ticket on your premises, so we
                    know Markus has been here.” <br />
                    Adam got inside the van. <br />
                    "Put your hands where I can see them!" the policeman
                    shouted. Adam took a pistol out of the glove compartment and
                    pointed it at the cops. Before Adam could do anything, he
                    felt a bullet enter his body, and two more shortly after
                    that. Adam fell down onto the lawn. Adam saw the policemen
                    kneeling down beside him and said, "Tell Walter I'm sorry...
                    I never meant to do him harm…" <br />
                    The policemen looked at each other wondering what Adam
                    meant, but at that moment, Adam passed out.
                </ParagraphStyled>
                <ParagraphStyled>
                    Adam opened his eyes and was lying on the floor. He saw two
                    nurses kneeling down beside him, one of them holding a
                    bloody pen, and behind him a few nurses were forcing Walter
                    to the floor. Just before drawing his last breath, Adam
                    said, "Tell their parents I'm sorry. I needed their children
                    to save us all."
                </ParagraphStyled>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StoryAThinLine;
