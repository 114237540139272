import React, { useContext } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MainPageLayout from "../components/MainPageLayout";
import { protectedRoutes } from "./../routes";
import { AccountContext } from "../context/AccountContext";

const AdminLogin = ({ history, location }) => {
    const { account, loading, error, login } = useContext(AccountContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const loginCredentials = {
            email: formData.get("email"),
            password: formData.get("password"),
        };

        login(loginCredentials);
    };

    if (account.loggedIn) {
        return <Redirect to={protectedRoutes.adminDashboard} />;
    }

    return (
        <MainPageLayout>
            <Container maxWidth={"xs"}>
                <Box
                    sx={{
                        marginTop: 15,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box component={"form"} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth={true}
                            id="email"
                            label="E-mail"
                            error={!!error}
                            name="email"
                            autoComplete="email"
                            variant="filled"
                            autoFocus
                            sx={{ fontSize: "6px" }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth={true}
                            name="password"
                            label="Password"
                            error={!!error}
                            helperText={error ? "You are unworthy..." : ""}
                            type="password"
                            id="password"
                            variant="filled"
                            autoComplete="current-password"
                            sx={{ fontSize: "6px" }}
                        />
                        <Button
                            fullWidth
                            variant={"outlined"}
                            type={"submit"}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Container>
        </MainPageLayout>
    );
};

export default withRouter(AdminLogin);
