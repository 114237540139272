import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { visuallyHidden } from "@mui/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";
import DeleteModal from "./DeleteModal";
import ResponsiveTableContainer from "./ResponsiveTableContainer";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const TableSectionHead = (props) => {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell key={`padding-header-cell`} padding="checkbox" />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

TableSectionHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const TableSectionToolbar = (props) => {
    const {
        numSelected,
        handleOpenAddModal,
        handleOpenUpdateModal,
        handleOpenDeleteModal,
        titleSingle,
    } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {""}
                </Typography>
            )}

            {numSelected > 0 && (
                <>
                    <Tooltip title={`Edit ${titleSingle}`}>
                        <IconButton onClick={handleOpenUpdateModal}>
                            <EditIcon sx={{ color: "#b1b1b1" }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`Delete ${titleSingle}`}>
                        <IconButton onClick={handleOpenDeleteModal}>
                            <DeleteIcon sx={{ color: "#b1b1b1" }} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
            <Tooltip title={`Add ${titleSingle}`}>
                <IconButton onClick={handleOpenAddModal}>
                    <AddCircleIcon sx={{ color: "#b1b1b1" }} />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

TableSectionToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const TableSection = (props) => {
    const {
        headCells,
        extraCrudFields,
        validationSchemaAdd,
        validationSchemaUpdate,
        titleSingle,
        defaultSortField,
        data,
        onAddEvent,
        onUpdateEvent,
        onDeleteEvent,
    } = props;
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState(defaultSortField);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = useState(Array(5));
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openAddModal, setOpenAddModal] = useState(false);
    const handleOpenAddModal = () => setOpenAddModal(true);
    const handleCloseAddModal = () => setOpenAddModal(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const handleOpenUpdateModal = () => setOpenUpdateModal(true);
    const handleCloseUpdateModal = () => {
        setSelected([]);
        setOpenUpdateModal(false);
    };
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => {
        setSelected([]);
        setOpenDeleteModal(false);
    };

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const matches = useMediaQuery("(min-width:760px)");

    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(row);
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (row) => selected.indexOf(row) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const getRowCellsWithData = (row, index) => {
        const tableCells = [];
        for (const [key, value] of Object.entries(row)) {
            const { type } = headCells.find((cell) => {
                return cell.id === key;
            });
            //TODO: make into switch statement and default insert Skeleton so loading check isn't necessary
            if (type === "string") {
                tableCells.push(
                    <TableCell key={`${key}=${index}`} align={"left"}>
                        {value}
                    </TableCell>
                );
            }
            if (type === "date") {
                tableCells.push(
                    <TableCell key={`${key}=${index}`} align={"left"}>
                        {value}
                    </TableCell>
                );
            }
            if (type === "boolean") {
                tableCells.push(
                    <TableCell key={`${key}=${index}`} align={"left"}>
                        <Checkbox
                            disabled
                            color="primary"
                            checked={value}
                            sx={{
                                padding: matches ? "inherit" : "2px 0px 2px 0",
                                marginLeft: matches ? "inherit" : "-5px",
                                transform: matches ? "inherit" : "scale(0.7)",
                            }}
                        />
                    </TableCell>
                );
            }
        }
        return tableCells;
    };

    return (
        <Box sx={{ width: "100%" }}>
            <ResponsiveTableContainer headCells={headCells}>
                <AddModal
                    onSubmit={onAddEvent}
                    titleSingle={titleSingle}
                    open={openAddModal}
                    handleClose={handleCloseAddModal}
                    headers={headCells}
                    extraCrudFields={extraCrudFields}
                    validationSchema={validationSchemaAdd}
                />
                {selected.length > 0 && (
                    <UpdateModal
                        onSubmit={onUpdateEvent}
                        titleSingle={titleSingle}
                        open={openUpdateModal}
                        handleClose={handleCloseUpdateModal}
                        headers={headCells}
                        initialFormData={selected[0]}
                        extraCrudFields={extraCrudFields}
                        validationSchema={validationSchemaUpdate}
                    />
                )}
                {selected.length > 0 && (
                    <DeleteModal
                        onSubmit={onDeleteEvent}
                        titleSingle={titleSingle}
                        open={openDeleteModal}
                        handleClose={handleCloseDeleteModal}
                        entityToDelete={selected[0]}
                    />
                )}
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableSectionToolbar
                        handleOpenAddModal={handleOpenAddModal}
                        handleOpenUpdateModal={handleOpenUpdateModal}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        numSelected={selected.length}
                        titleSingle={titleSingle}
                    />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: matches ? 750 : 0 }}
                            aria-labelledby="tableTitle"
                            size={"small"}
                        >
                            <TableSectionHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={() => {
                                    console.log("Ooohhhh");
                                }}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={headCells}
                            />
                            <TableBody>
                                {rows
                                    .slice()
                                    .sort(getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    handleClick(event, row)
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                {matches && (
                                                    <TableCell
                                                        key={`padding-row-${index}`}
                                                        padding="checkbox"
                                                    />
                                                )}
                                                {getRowCellsWithData(
                                                    row,
                                                    index
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && matches && (
                                    <TableRow
                                        style={{
                                            height: 49 * emptyRows,
                                        }}
                                    >
                                        <TableCell
                                            sx={{ borderBottom: "none" }}
                                        />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={matches ? [5, 10, 25] : [5]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </ResponsiveTableContainer>
        </Box>
    );
};

export default TableSection;
