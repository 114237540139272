import axios from 'axios';
import { createBrowserHistory } from 'history';
import { routes } from './../routes'

const httpClient = axios.create({ withCredentials: true });

const logOutFromClient = () => {
        console.log('Logging out...')
        localStorage.getItem('userName') && localStorage.removeItem('userName')
        localStorage.getItem('userEmail') && localStorage.removeItem('userEmail')
        localStorage.getItem('access_token') && localStorage.removeItem('access_token')
        localStorage.getItem('userLoggedIn') && localStorage.removeItem('userLoggedIn')
        createBrowserHistory().push(routes.adminLogin);
        window.location.reload();
} 

httpClient.interceptors.request.use((config) => {

    const token = localStorage.getItem('access_token');

    config.headers = {
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json;charset=utf-8"
    };
    return config;
}, (error) => {
    return Promise.reject(error);
})

httpClient.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const status = error.response?.status || 500;
      // we can handle global errors here
      switch (status) {
        // authentication (token related issues)
        case 401: {
          logOutFromClient()
          return Promise.reject(new Error(error, 409));
        }
  
        // forbidden (permission related issues)
        case 403: {
          logOutFromClient()
          return Promise.reject(new Error(error, 409));
        }
  
        // bad request
        case 400: {
          return Promise.reject(new Error(error, 400));
        }
  
        // not found
        case 404: {
          return Promise.reject(new Error(error, 404));
        }
  
        // conflict
        case 409: {
          return Promise.reject(new Error(error, 409));
        }
  
        // unprocessable
        case 422: {
          return Promise.reject(error.response);
        }
  
        // generic  error (server related) unexpected
        default: {
          return Promise.reject(new Error(error, 500));
        }
      }
    }
  );

  export default httpClient;