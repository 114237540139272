import HomeMenu from "./components/HomeMenu"
import ShowsOverview from "./pages/ShowsOverview"
import StoriesOverview from "./pages/StoriesOverview"
import StorySelectionPage from "./pages/StorySelectionPage"
import StoryIllumniFhtagn from "./pages/StoryIllumniFhtagn"
import StoryElLizardBirth from "./pages/StoryElLizardBirth"
import StoryAThinLine from "./pages/StoryAThinLine"
import StorySvartalfr from "./pages/StorySvartalfr"
import StoryUmibozu from "./pages/StoryUmibozu"
import StorySupernaturalSelection from "./pages/StorySupernaturalSelection"
import Biography from "./pages/Biography"
import AdminLogin from "./pages/AdminLogin"
import AdminDashboard from "./pages/admin/AdminDashboard"

export const routes = {
    illumniFhtagn: '/stories/reliqideus/illumni-fhtagn',
    elLizardBirth: '/stories/reliqideus/el-lizard-birth',
    aThinLine: '/stories/reliqideus/a-thin-line',
    svartalfr: '/stories/reliqideus/svartalfr',
    umibozu: '/stories/reliqideus/umibozu',
    superNaturalSelection: '/stories/reliqideus/supernatural-selection',
    reliqideus: '/stories/reliqideus',
    main: '/main',
    stories: '/stories',
    shows: '/shows',
    bio: '/bio',
    store: 'http://cthuluminati.bigcartel.com',
    adminLogin: '/cultmeister/login'
}

export const componentTree = [
    { path: routes.elLizardBirth, name: 'ElLizardBirth', Component: StoryElLizardBirth },
    { path: routes.aThinLine, name: 'AThinLine', Component: StoryAThinLine },
    { path: routes.illumniFhtagn, name: "IllumniFhtagnh", Component: StoryIllumniFhtagn },
    { path: routes.svartalfr, name: "Svartalfr", Component: StorySvartalfr },
    { path: routes.reliqideus, name: "Reliqideus", Component: StorySelectionPage },
    { path: routes.umibozu, name: "Umibozu", Component: StoryUmibozu },
    { path: routes.superNaturalSelection, name: "SupernaturalSelection", Component: StorySupernaturalSelection },
    { path: routes.main, name: "Main", Component: HomeMenu },
    { path: routes.stories, name: "Stories", Component: StoriesOverview },
    { path: routes.shows, name: "Shows", Component: ShowsOverview },
    { path: routes.bio, name: "Biography", Component: Biography },
    { path: routes.adminLogin, name: "AdminLogin", Component: AdminLogin },
]

export const protectedRoutes = {
    adminDashboard: '/cultmeister/dashboard'
}

export const protectedComponentTree = [
    { path: protectedRoutes.adminDashboard, name: "AdminDashboard", Component: AdminDashboard },
]