  
export const initialState = {
    loading: false,
    error: '',
    account: {
        name: localStorage.getItem('userName') ?? null,
        email: localStorage.getItem('userEmail') ?? null,
        token: localStorage.getItem('access_token') ?? null,
        loggedIn: localStorage.getItem('userLoggedIn') ?? false,
    }
}

export const accountReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        loading: true,
        ...state
      }
    case "LOGIN":
      return {
        loading: false,
        error: '',
        account: {
          name: action.payload.name,
          email: action.payload.email,
          token: action.payload.access_token,
          loggedIn: true,
        }
      }
    case "LOGIN_ERROR":
      return {
        loading: false,
        error: 'Could not login',
        account: {}
    }
    default:
      return state
  }
}
