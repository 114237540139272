import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import styled from "styled-components";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";

const StoryIllumniFhtagn = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <StoryHeaderStyled>
                    <BackButton route={routes.reliqideus} />
                    Story Illumni Fhtagn
                </StoryHeaderStyled>
                <StorySubHeaderStyled>Coming soon</StorySubHeaderStyled>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StoryIllumniFhtagn;
