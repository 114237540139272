import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const AnimatedElement = styled.div`
    opacity: 0;
    animation-name: ${fadeIn};
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

export default AnimatedElement;
