import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";

const StorySvartalfr = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <StoryHeaderStyled>
                    <BackButton route={routes.reliqideus} />
                    Svartálfr
                </StoryHeaderStyled>
                <StorySubHeaderStyled>Norway, June 2015</StorySubHeaderStyled>

                <ParagraphStyled>
                    It was one of those scarce hot summer days. The temperature
                    was above twenty-five degrees Celsius, which it almost never
                    was in Trondheim. Five friends were sitting in a field, just
                    outside Trondheim. Aron, Mikael, Simon, Frey, and Aura all
                    just graduated from the Norwegian University of Science and
                    Technology. They were enjoying the sun, making music,
                    and&nbsp; discussing volcanic eruptions of the past century.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Eeeedjaaaafjalllalaluul…" Simon said with such great
                    effort, his face looked like he was taking a dump. He was a
                    small, chubby guy with a goatee, and a boyish-like face. He
                    took a hit from a bong, and passed it to Mikael.
                    <br />
                    "It's Eyjafjallajökull, the last part is pronounced with a
                    silent T." Mikael said, "But don't you even try to pronounce
                    it, you're way too dumb to get it right." he said with a
                    smile on his face, as he took the bong from Simon.
                </ParagraphStyled>
                <ParagraphStyled>
                    Mikael, a tall, good looking young man, with a confident
                    look that sometimes could be mistaken for arrogance, took a
                    shot from the bong, and passed it on. "I sometimes wonder
                    how the hell you passed your exams, with that brain of
                    yours." he said laughing out loud. At this, Simon stormed at
                    Mikael, trying to tackle him. Mikael, who saw this coming,
                    evaded Simon and took him in a headlock. Everybody looked at
                    them and started laughing, except for Aron. Aron, who looked
                    like a rocker, with a long beard and dreadlocks, wearing a
                    Kyuss shirt, was drawing a picture.
                </ParagraphStyled>
                <ParagraphStyled>
                    "What are you drawing this time?" Aura asked. She was the
                    only girl in the group. She was a small girl, with a pretty
                    face, with whom all the men at the university would have
                    loved to have some quality time. All the guys in the group
                    tried to seduce her, but they all failed, miserably.
                </ParagraphStyled>
                <ParagraphStyled>
                    "I'm almost done." Aron said, absently. <br />
                    "But I want to see it now…" Aura said while grabbing the
                    picture and snatching it out of Aron's hand. "This is
                    awesome!" she exclaimed, "A volcano and a troll, this is
                    beautiful."
                    <br />
                    "A troll, like me?" Frey asked while putting his guitar
                    aside. Like Aron he looked like a rocker, wearing a
                    lumberjack shirt over his Black Sabbath shirt, which was
                    barely visible due to his long, curly hair, that was hanging
                    over it. <br />
                    "No not that kind of troll, you're just being an asshole to
                    people on the internet." Aura said. "Only to the ones who
                    ask for it…" Frey said with a grin on his face.
                    <br />
                    "No it's a troll, like from the fairy tales." Aura said, and
                    showed the drawing to the group.
                    <br />
                    It was a drawing of a giant troll, using a volcano as a
                    bong. He used a snake to take shots from the volcano bong
                    and blow lots of smoke onto a forest.
                </ParagraphStyled>
                <ParagraphStyled>
                    "It's a drawing about a story, that's called Svartálfr."
                    Aron explained, "It is said that every time a volcano
                    erupts, and the smoke and ashes cover a forest, a plant
                    grows in that forest. And after four years, the plant is
                    fully grown. The plant is called Svartálfr. If you pick the
                    plant, you must smoke it immediately, or else it doesn't
                    work anymore. If you smoke the Svartálfr, you get the
                    biggest trip ever, and you will have the answers to all
                    life's questions."
                    <br />
                    "Interesting, all life's questions answered…" Mikael said.{" "}
                    <br />
                    "Biggest trip ever!" Simon said at the same time, still with
                    his head between Mikael's arm and thigh.
                    <br />
                    "Good to know where both your priorities are!" Aron said
                    laughing. "The Eyjafjallajökull erupted in 2010, so the
                    Svartálfr should be fully grown by now…" he added.
                </ParagraphStyled>
                <ParagraphStyled>
                    “Yeaahhh, I have to call bullshit here. Why haven’t I heard
                    this story before?” Aura looked straight at Aron, her
                    demeanor had changed to serious within a second. “We… ehh…”
                    Aron tried to come up with a quick answer, but Aura didn’t
                    give him enough time.
                    <br />
                    “I mean all these years we study at the University of
                    Science and Technology, where at least fifty percent of the
                    students do or have done drugs of all sorts, don’t you think
                    some time in those years someone would have brought up the
                    possibility of taking a trip like this?”
                    <br />
                    She stared at Aron with eyes that expected an indisputable
                    answer if not none at all.
                    <br />
                    “That’s the point!” Aron immediately replied. “Everyone who
                    hears the story dismisses it as a made up story, laughs and
                    forgets about it. It’s not that I believe it’s true, nor do
                    I think the opposite. As a scientist you always have to be
                    open minded, remember? I’m just curious and I love Iceland,
                    so why not go over there and check it out. If it turns out
                    to be true we take the trip of a lifetime if it’s not we had
                    a trip to remember for a lifetime.”
                </ParagraphStyled>
                <ParagraphStyled>
                    Aura didn’t respond to this, but seemed to overthink what
                    Aron had said.
                    <br />
                    Simon struggled himself free from Mikael's grip, and walked
                    to his backpack. <br />
                    "A drink, anyone?" he asked. <br />
                    "Come on Simon, you know we don't drink that moonshine shit,
                    you create at home." Mikael said dismissively, "You watch
                    the discovery channel a few times, and you think you can do
                    the same as those hillbillies in an American forest. You
                    better watch out you don't damage those few brain cells
                    you’ve got left with that rubbish."
                    <br />
                    "I got a PhD in that kind of shit." Simon said defensively,
                    "And why do you always have to pick on me, try someone else
                    for a change." <br />
                    "Come on Simon, my chubby little friend, let's be honest…"
                    Mikael replied, "We've got a pretty girl, an artist, a
                    musician, and me, the handsome one. You are the small, fat
                    one, attempting to grow a beard beneath your fat lip. You're
                    bound to be the one getting picked on.”
                    <br />
                    "You know you are the biggest..." but before Simon could
                    finish his sentence Aron interrupted him. <br />
                    "Seriously guys, this could be an excellent road trip." he
                    said, getting on his feet. "We've all been asking ourselves
                    what to do after we finished studying , but no one has come
                    up with anything yet."
                </ParagraphStyled>
                <ParagraphStyled>
                    "I did." Frey said, "I'm going to New Zealand for a year,
                    backpacking. I'll be doing some work to earn money for
                    partying, but believe me, partying will cover most of the
                    time. I'm certainly not going to some volcanic island,
                    searching for a plant from a fairy tale, to get high. Over
                    there I know there will be weed, alcohol, and maybe some
                    acid, if I'm lucky. Going to find me a pretty Maori girl and
                    rock her world. Sorry guys, I've known you for quite some
                    years now, but this is where our paths part, it's time for
                    new people, with new stories."
                </ParagraphStyled>
                <ParagraphStyled>
                    "It may be a little more than just a fairy tale." Aron said,
                    a bit disconcerted. "My mother who -as you all know- is from
                    Iceland, used to tell me the story as a child, but she
                    obviously didn't mention the tripping part. I've looked it
                    up on the internet and there's actual proof the Svartálfr
                    exists, with photos and everything. So how about it?" he
                    asked, looking at the others.
                    <br />
                    "Sounds exciting." Simon said, "I always wanted to see
                    Iceland someday, and my Job in Oslo doesn't start until
                    October. I'll go with you, Aron."
                    <br />
                    "Yeah sure, why not." Mikael said." Got nothing planned for
                    the rest of the year, and someone has to watch over Danny
                    DeVito here." he said while pinching Simon's cheek, who
                    immediately pushed Mikael away.
                    <br />
                    "How about you? I could use a pretty girl, to compensate for
                    these two ugly bastards." Mikael asked Aura who looked at
                    them in disbelief.
                    <br />
                    "You really want to go to Iceland to smoke a rare plant that
                    only grows if a volcano throws its ashes onto the soil of a
                    remote forest? You guys are mental… This I've got to see,
                    count me in!" she said with a smile on her face.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Alright, that's settled then!" Aron said, "I've got a
                    cousin in Reykjavík, I will give him a call and ask him if
                    we can stay at his place for a few days, before going into
                    the wild. Are you sure you don't want to come, Frey?" "And
                    miss out on my sweet Maori girl? Thanks but no thanks." Frey
                    said, while packing his guitar, "You all go and have fun
                    searching for that miracle plant of yours. Let me know if
                    you've found it. Just post it on Facebook or something, if
                    they got internet in that wasteland." He grabbed his stuff
                    and gave Aura a kiss. "Please take care of those imbeciles
                    and be safe." he said to her. He waved them goodbye, got in
                    his car and drove off.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Ok." Aron said, with his phone in his hand, "We can book a
                    last minute flight, this Friday. We fly from Trondheim
                    airport to Reykjavík, we'll be there in two and a half
                    hours.”
                    <br />
                    "But, that’s in two days…" Simon uttered. <br />
                    "Already chickening out Bilbo?" Mikael chuckled.
                    <br />
                    "No way, dickhead!" Simon shouted, "I'm coming. We'll see
                    how big your mouth is, after we smoked that plant."
                    <br />
                    "Oh snap!" Aura said, padding Simon on the shoulder.
                    <br />
                    "Cool. So I'll see you all on Friday?" Aron asked, and
                    everybody nodded in agreement. “Good. Don't forget to book
                    your tickets."
                    <br />
                    They packed their things, and each went their own way.
                </ParagraphStyled>
                <ParagraphStyled>
                    Trondheim airport wasn't a big airport, it was just nineteen
                    kilometers outside Trondheim, in a small town called Værnes.
                    Aron was already&nbsp; waiting in the departure hall, when
                    the others arrived.
                    <br />
                    "There you are!" Mikael called out from behind a large group
                    of German tourists, "Jesus, not again I hope." he said,
                    pointing his head to the group of Germans, "I thought we
                    would be rid of them for good, after we chased them away
                    seventy years ago. Aron, my good friend, we are all here,
                    ready to go, and I've got something sweet for during the
                    flight." He took a package of Oreos out of his backpack. "I
                    sprinkled the cookies with FM-WL, a nice little drug I
                    created myself, that should make our flight a bit more
                    pleasant."
                    <br />
                    "FM-WL, is that the scientific name for it?" Simon asked,
                    curiously.
                    <br />
                    "No, it means From Mikael With Love..." Mikael said with a
                    broad smile on his face, as he repacked the cookies into his
                    backpack.
                    <br />
                    "Really?" Aura said, "I hope this one is less potent than
                    the last drug you created. I was high for three days. I was
                    high all day on my grandmother's eightieth birthday party.
                    Everybody was asking me if I was sick, the whole day."
                    <br />
                    "Well, good to know I’ve helped to make such a boring
                    occasion more fun." Mikael said, proudly. "Don't worry, this
                    one only works for two hours or so. Oh yeah, that reminds
                    me…" he said, while grabbing the cookies again, "You really
                    want it to kick in when we take off, so we better take it
                    now."
                    <br />
                    Mikael gave everyone a cookie. Simon put the whole thing in
                    his mouth, instantly, and started chewing like he hadn't
                    eaten for a week. Aron and Aura looked at each other, a bit
                    doubtful , but took the cookies and ate them.
                    <br />
                    "Good." Mikael said, "Let's give new meaning to the mile
                    high club!"
                </ParagraphStyled>
                <ParagraphStyled>
                    An hour later they checked in and they boarded the plane.
                    Mikael and Simon got on first. They were fooling around and
                    holding up the line.
                    <br />
                    "Come on guys," Aron said, walking right behind them. When
                    he said the sentence, it felt like his mouth&nbsp; grew
                    several centimeters, and it seemed the ‘uy’ part of ‘guys’
                    lasted for a few minutes. <br />
                    "Siiiiirrrr.... ssiiirr... siiiirrrr?" &nbsp;The word echoed
                    in his mind. He looked up. "Sir, are you alright?" a slender
                    looking stewardess asked.
                    <br />
                    "What? Yeah, I'm ok…" Aron said, coming back to his senses,
                    "I better sit down." he said. The stewardess looked at his
                    ticket and showed him to his place. As he followed the
                    stewardess the aisle of the plane seemed to stretch out for
                    miles and the stewardess became very small. As they arrived
                    at the seat everything was back to normal. Aron sat down
                    besides Aura and took a deep breath. Simon and Mikael, who
                    sat in front of them, looked at Aron.
                    <br />
                    "Are you alright buddy?" Mikael asked with a grin on his
                    face. Aron looked at him and saw his face whirl, at first to
                    the left and then slowly to the right. <br />
                    "Yeah, I'm fine." Aron said a bit groggy, "It's really
                    kicking in now." <br />
                    "Great stuff, isn't it!" Mikael said as he looked fascinated
                    at the back of&nbsp; his hand, which he was moving&nbsp;
                    back- and forward. "Wait until we take off, that should be
                    fun."
                    <br />
                    Simon was giggling. He sat down and fastened his seatbelt.{" "}
                    <br />
                    "Bring it on!" he said.
                </ParagraphStyled>
                <ParagraphStyled>
                    ''Here we go!" Mikael said joyfully, as the plane took off a
                    few minutes later.
                    <br />
                    The minute the plane took off, Aron felt himself pushed back
                    in his seat, like he was lying in a waterbed with not enough
                    water in it. Not knowing if he liked this feeling or not,
                    Aron closed his eyes. Not a good idea. He felt like he was
                    falling into a bottomless pit and opened his eyes instantly.
                    As he looked to the front of the plane, he saw all the seats
                    and windows falling down to him as if they weren't installed
                    properly&nbsp; and all came loose because of the plane
                    hanging diagonally. In front of him, Mikael and Simon were
                    making sounds of joy and fright, like they were sitting in
                    the world's scariest roller coaster, but they were obviously
                    having fun. He looked at Aura beside him. Smiling she asked,
                    "Having fun yet?"
                    <br />
                    As he looked at her, her face was throbbing in the shape of
                    a balloon.
                    <br />
                    "Yeah, this is great." He said convincingly, but he didn't
                    like it at all and was praying the drug would wear off soon.{" "}
                    <em>Man up</em>, he thought to himself,{" "}
                    <em>
                        you've been here before. There's nothing wrong, it's
                        only drugs
                    </em>
                    .
                </ParagraphStyled>
                <ParagraphStyled>
                    The plane leveled at thirty-five thousand feet and Aron was
                    starting to feel better. <br />
                    "That was freaking awesome!" Simon exclaimed. <br />
                    "See, I told you!" Mikael said in excitement. <br />
                    They both released their seatbelts, turned around, sat with
                    their knees on the seat and their arms on top of the
                    headrest, both grinning at Aron and Aura.
                    <br />
                    "And how was it for you both, you've not been touching each
                    other in inappropriate places, I hope…" Mikael said to them,
                    raising one of his eyebrows.
                    <br />
                    "That was excellent," Aura said, "And what if we did?" she
                    said while stroking Aron's leg.
                    <br />
                    "And you, good sir, been having fun I presume?" Mikael said
                    looking at Aron.
                    <br />
                    "Yea.. Yeah." Aron said a bit distracted, "Yes, best fun I
                    had in ages." he said more certain, "But I could really use
                    a drink now."
                    <br />
                    "My thoughts exactly!" Mikael said while turning around and
                    stopping a stewardess who was just walking past them. "Could
                    we order some drinks?" he asked her. <br />
                    "Certainly." the stewardess replied. <br />
                    "The usual?" He asked, looking at the others. Without
                    waiting for confirmation he turned back to the stewardess.
                    "A diet Coke for the lady, sparkling water for my friend
                    behind me, a beer for the short stubby man next to me."
                    <br />
                    "No, no beer for me!" Simon tried to protest.
                    <br />
                    "Yes, you'll have a beer, you always do, don't you…" Mikael
                    said looking at Simon in disbelief.
                    <br />
                    "Oh yeah, right you are." Simon said with a big smile on his
                    face.
                    <br />
                    "And for me an iced tea, please." Mikael continued.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    The drinks arrived and everybody was discussing what they
                    wanted to do the first few days in Reykjavik. After Simon
                    finished half of his beer he started to turn pale and he was
                    sweating a lot.
                    <br />
                    "I'm not feeling so well…" he said and he started grabbing
                    for the vomit bag in front of him. Just seconds later he
                    started vomiting into the bag. <br />
                    "Ahh shit, I forgot to mention!" Mikael said snorting, “My
                    drug doesn't mix well with beer it almost certainly will
                    make you puke. I'm so sorry Simon, I should have told you."
                    <br />
                    "Is your friend ok?" a stewardess asked, a bit worried.
                    <br />
                    "Yes he's fine." Mikael replied, "But no more beer for my
                    friend, he obviously can't handle it."
                    <br />
                    Simon looked at Mikael full of rage. He tried saying
                    something to him, but instead he continued filling the vomit
                    bag. The others could not help laughing about it.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    The plane landed at Keflavík International Airport, they got
                    off the plane. Everybody was sober again and talked
                    excitedly about being in Iceland, except Simon who still was
                    wiping the sick sweat of his face. As they entered the
                    arrival hall Aron looked around if he could see his cousin,
                    who agreed to pick them up from the airport.
                    <br />
                    "There you are!" Aron heard behind him as he felt someone
                    putting his hand on his shoulder. Aron turned around in
                    surprise. <br />
                    "Jón!" he shouted, as he embraced his cousin. "Jesus, it's
                    been how long?" <br />
                    "Too long, if you ask me." Jón said "How have you been?"
                    <br />
                    "I'm doing great. Just finished my study." Aron replied.
                    "Guys, this is Jón, my cousin. Jón, these are Aura, Mikael,
                    and the guy in the back is Simon."
                    <br />
                    "Are you alright?" Jón asked a bit concerned as he looked at
                    Simon.
                    <br />
                    "Nothing to worry about." Mikael said grinning, "He's just a
                    bit airsick, that's all."
                    <br />
                    "Well good." Jón said, "Just follow me, I've got my van
                    parked nearby. First we go to my place so you can all settle
                    in. A friend of mine is having a party at his place tonight.
                    We can go there if you all feel like it."
                    <br />
                    "Sure!" Aron and Aura said simultaneously. <br />
                    "That sounds great." Mikael said. "And short ribs here is
                    always in for a party. He'll be fine after he had a shower."
                    he said, ruffling Simon’s hair.
                </ParagraphStyled>
                <ParagraphStyled>
                    Driving away from the airport into Reykjavik, Jón put on
                    some music. <br />
                    "You're still listening to this mellow shit?" Aron asked, as
                    he listened to the music.
                    <br />
                    "Oh dude do not disrespect Sigur Rós when you're in Iceland,
                    or else it could end badly for you." Jón said laughing. "And
                    you're in luck, at my friends party the only music you're
                    going to hear is music from Icelandic bands. It's the only
                    thing he listens to. Except Björk, he hates her. Don't ask
                    me why."
                    <br />
                    "Well, amen to that." Simon said, "As long as it includes
                    some metal I'm fine with that." he added.
                    <br />
                    "Oh it will, don't worry!" Jón said, "And believe me, after
                    some mushrooms you're going to like the music for sure."
                    <br />
                    "Cool, mushroom party! I'm starting to like this country
                    more and more." Mikael said cheerfully.
                </ParagraphStyled>
                <ParagraphStyled>
                    After they settled in and freshened up, they went to the
                    party. It was a ten minute walk and though it was pretty
                    cold they all were enjoying the walk. The party was already
                    in full when they arrived. Jón introduced them to his
                    friends and fixed some drinks. After some time of getting to
                    know each other and lots of small talk, Aron sat down to
                    talk to Jón. He told him the reason why they came to
                    Iceland. <br />
                    "Ahh, the Svartálfr…" Jón said, "Well I never believed that
                    story, but more people came over here and went looking for
                    it. The strange thing about it is, some of them disappeared
                    and nobody has ever seen them again. Nature in Iceland can
                    be dangerous, so be careful."
                    <br />
                    "Who wants to have a trip?" Jón's friend asked after taking
                    a large bag of dried mushrooms out of a cupboard. Aron
                    looked up and saw Mikael and Simon jump for joy. Aura was
                    looking at Aron, inclining her head. "Yeah sure, why not."
                    he said, "Let's have fun."
                </ParagraphStyled>
                <ParagraphStyled>
                    Everybody took the mushrooms and after the party went on for
                    two hours they all started tripping. Mikael flirted with a
                    girl and took her somewhere more peaceful. Simon was
                    watching a snake in its terrarium. It was the house owners
                    pet and it was feeding time, so Simon and some others were
                    fixated on the mouse which was put in an hour before, hoping
                    the snake would act soon. Aura was dancing and totally in
                    her own world. Aron was sitting on the couch still talking
                    to Jón, who was smoking a joint. The mushrooms were really
                    kicking in as they were talking. Jón took a big hit from the
                    joint and exhaled it right into Aron's face. For a moment
                    all that Aron could see was smoke. As the smoke disappeared
                    he found himself floating above a large pool of lava. A bit
                    bewildered he looked around and saw that he was surrounded
                    by massive stone walls. Before he realized what was going on
                    he was pushed up to a massive hole by a stream of warm air
                    and the lava started to squirt all around him. He went up to
                    the hole and as he reached it, he realized he was being
                    pushed out of a crater of a volcano. Once he was outside of
                    the volcano he flew away from it, above land, accompanied by
                    smoke and ashes. He saw himself flying towards a big remote
                    forest. The moment he hung over the forest he descended into
                    it. He sat on the soil of the forest and looked around,
                    wondering what was going on. That moment he saw a beam of
                    light shining on a small plant in the middle of the forest.
                    The plant had a strange kind of attraction to him. He walked
                    to the plant and he felt the urge to pick the plant. As he
                    did so, the plant withdrew itself and the beam of light
                    disappeared. Aron heard a growling noise, he looked to his
                    left and saw a silhouette of a creature which reminded him
                    of a goblin from Lord of the Rings. The creature stared at
                    him with bright white eyes. Frightened, he tried to run away
                    but he couldn't move. It felt like he was turned to stone.
                    The creature walked right at him, stopped in front of him
                    and took his head between both of its hands.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Aron.... Aron, are you ok?!"
                    <br />
                    Aron looked up in shock. He saw his cousin holding his head
                    and looking at him, a bit worried. <br />
                    "Whh.. what? Yeah, yes, I'm fine." he said, looking a bit
                    puzzled. "I just had the weirdest trip about...."
                    <br />
                    "Ahhhh that was brilliant!" Aron heard Simon shout. He
                    looked at the people standing in front of the terrarium and
                    saw them all laugh and cheer. Apparently the damn snake
                    finally has eaten the mouse, Aron thought. <br />
                    "So what happened in your trip?" Jón asked, still a bit
                    concerned.
                    <br />
                    "Well, never mind…" Aron said, and he already felt a lot
                    better. "How long was I gone?"
                    <br />
                    "Just a few minutes." Jón answered, "But your eyes moved
                    from the left to the right beneath your eyelids, just like
                    rapid eye movements, but that's not possible within a few
                    minutes."
                    <br />
                    "Just forget about it." Aron said and he got up to stretch
                    himself. <br />
                    That moment Mikael re-entered the room.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "Did I miss something?" He asked. <br />
                    "Yeah, the snake finally ate the mouse!" Simon said
                    enthusiastically. <br />
                    "Well, looks like you might have had more fun than I did,"
                    Mikael said with a smile on his face, as he padded the girl
                    who went with him on her butt. The girl smiled at Mikael and
                    mingled with the others. The rest of the party everybody was
                    having fun. Aron was feeling better again and enjoyed the
                    remaining of his trip like everybody else. At four in the
                    morning they decided to leave and they all went back to
                    Jón's place. After arriving at Jón's house they had one more
                    drink before they went to bed. Aron was sleeping in a small
                    room in the attic. The minute his head touched the pillow he
                    fell asleep.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Do not come here… and steal my plants!!" a low voice
                    bellowed. <br />
                    Aron looked up and stared into two big white eyes, they had
                    no pupils. He was back in the forest again and saw big black
                    clouds swirling through the trees behind the creature's
                    head. He still couldn't move and he felt very cold. <br />
                    "You hear me!!" the creature shouted at him. "Stay away from
                    here and from my plants, or else you will regret it! Stay
                    Away!!"
                </ParagraphStyled>
                <ParagraphStyled>
                    Aron woke up and sat up straight in his bed instantly, the
                    word ‘away’ still echoing&nbsp; in his head. The sun was
                    shining through a small window in his room. To Aron it felt
                    he just slept for five minutes, but when he checked his
                    phone he saw it was already eleven thirty. He got up and
                    washed himself at a small sink and went downstairs.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Good morning, sleeping beauty." Aura said to him." Would
                    you like some coffee?"
                    <br />
                    "Yes, please." he replied, and saw that everybody was up
                    already.
                    <br />
                    "Hey buddy, did you sleep well?" Jón asked.
                    <br />
                    "Yeah, it was fine." Aron lied, and he took the cup of
                    coffee Aura was holding out to him. <br />
                    "So you still want to go and search for that plant?" Jón
                    asked.
                    <br />
                    "Hell yeah!" Simon said, "Didn't bring this for nothing." He
                    said while holding up his bong he took out of his backpack.
                    <br />
                    "Absolutely." Aura and Mikael both said. Aron didn't say
                    anything and drank his coffee in silence. <br />
                    "How about you, little cousin?" Jón asked turning to Aron.{" "}
                    <br />
                    "Yeah, sure. That's what we came for isn't it." Aron said a
                    bit doubtful. <br />
                    "That didn't sound convincing, are you sure you're alright?"
                    Jón asked.
                    <br />
                    "Yes I'm fine!" Aron snapped. "Maybe I didn't sleep that
                    well after all…" He added more kindly.
                    <br />
                    "Well , just drink your coffee. Maybe you feel better
                    afterwards." Jón said while observing his cousin with some
                    concern. <br />
                    "Alright, you'll need a car and destination, I'll help you
                    with that." Jón continued.
                </ParagraphStyled>
                <ParagraphStyled>
                    The rest of the day everybody was making preparations for
                    the trip. Jón and Aron rented a car and planned the route.
                    The rest went shopping for groceries and camping equipment.
                    At the end of the day everybody was back at Jón's place for
                    dinner. <br />
                </ParagraphStyled>
                <ParagraphStyled>
                    "Excellent cooking, dude!" Mikael said to Jón, after the
                    meal. <br />
                    "Thanks! It's called hangikjöt." Jón said, "Smoked,
                    Icelandic sheep meat. They eat grass and herbs all year long
                    so the meat has been marinated the entire life of the
                    animal. I'm glad you like it."
                    <br />
                    "Jón and I have been looking at a forest, most likely being
                    one of the forests where the Svartálfr could grow." Aron
                    said. "We think the best place to find it is east of the
                    Eyjafjallajökull, the Núpsstaðarskógar forest. It's near a
                    place called Kálfafell. We can drive there tomorrow. <br />
                    "Roadtrip!" Simon yelled. <br />
                    "It's a three and a half-hour drive." Aron continued, "So I
                    suggest we go to bed early today and sleep until we wake up,
                    get some rest before we start our journey."
                    <br />
                    Everybody agreed to this, and after watching a movie they
                    all went to bed.
                </ParagraphStyled>
                <ParagraphStyled>
                    After Aron went upstairs he sat on the side of the bed for a
                    while. He didn't want to go to sleep right away. He was
                    thinking about his weird mushroom trip, and the disturbing
                    dream he had the previous night. Could it be real or just
                    some strange coincidence? Should he go to the forest or
                    should he go back home and forget about the whole thing?
                    <em>Ah come on</em>, he was saying to himself.{" "}
                    <em>
                        You're a scientist, there must be some logical
                        explanation for it
                    </em>
                    . He could not go home. after all it was him who persuaded
                    the others to come here and to go search for the Svartálfr.
                    They would&nbsp; never stop bothering him about it, the rest
                    of his life, if he went home now. Pull yourself together, he
                    thought, as he got into bed. As he tried to fall asleep he
                    was praying for a dreamless sleep. After half an hour of
                    staring at the ceiling heel fell asleep.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Aron!" he heard a voice say. Please, not again, Aron
                    thought. "Dude!" the voice continued, as he felt a pillow
                    hit his head. Aron opened his eyes and saw Mikael standing
                    next to his bed.
                    <br />
                    "Come on man. It's nine o'clock." Mikael said. "Everybody is
                    up already, and waiting for you. Come on downstairs,
                    breakfast is ready. We can't wait to go."
                    <br />
                    Aron, still a bit groggy, got up. He was glad he didn't had
                    that awful nightmare again. "Alright I'm coming." he said.
                    "Let me take a shower first."
                    <br />
                    "Sure." Mikael said, "But hurry up, Simon is so fired up to
                    go, it's getting annoying."
                </ParagraphStyled>
                <ParagraphStyled>
                    After Aron took a shower he went downstairs. All were having
                    breakfast already, as he joined them. After breakfast they
                    gathered their stuff and packed it into the car.
                    <br />
                    "Remember what I said." Jón said to Aron as they said their
                    goodbyes, "Nature in this country can be treacherous, so be
                    careful, alright?"
                    <br />
                    "No worries." Aura said while putting her arms around Aron's
                    neck, "We'll take good care of your little cousin. I promise
                    we'll bring him back in one piece."
                    <br />
                    Simon was already sitting in the backseat of the car,
                    bouncing up and down and eager to go.
                    <br />
                    Mikael was sitting in the driver's seat, adjusting the seat
                    to his comfort.
                    <br />
                    "Ready to go?" Aura asked.
                    <br />
                    "Yeah sure." Aron said without much confidence. <br />
                    "You can have shotgun." Aura said while getting onto the
                    backseat, "I'll sit next to Mister Overexcited here." as she
                    punched Simon on the shoulder.
                    <br />
                    "Hey, not nice!" Simon said.
                    <br />
                    "Well, remember to sit still then." Aura said frowning at
                    Simon," I got a lot more where that came from, so you better
                    behave."
                    <br />
                    "Yes mother." Simon said, making a face like a child who has
                    just been lectured and then started bouncing up and down
                    again.
                    <br />
                    Aura started to hit Simon with so much force, he had to put
                    his arms up in front of him not to get hurt.
                    <br />
                    "You better get going." Jón said to Aron, "Before they start
                    killing each other. Be safe ok, and call me if anything's
                    wrong.”
                    <br />
                    Aron gave his cousin a hug. "We'll be alright. We'll be back
                    in a week or so. After we've been to the forest, we'll make
                    a tour around the country, I'll stay in touch, ok?"
                    <br />
                    "Come on, let's chew up some asphalt!" Mikael said from
                    behind the wheel.
                    <br />
                    Aron got into the car and they drove off. <br />
                    "See you in a week!" Simon shouted with his head out of the
                    window.
                </ParagraphStyled>
                <ParagraphStyled>
                    It was eleven o'clock as they drove off and the sun was
                    shining. <br />
                    "We'll be at the forest around three in the afternoon."
                    Mikael said looking at the car navigation.
                    <br />
                    "That is, if the weather stays like this." Aron said looking
                    a bit worried up into the sky.
                    <br />
                    One hour outside of Reykjavik they were driving alongside
                    the south-coast of Iceland. <br />
                    There was a joyful atmosphere in the car and they were
                    making up band names for fictional bands. <br />
                    "Adaptor would be a cool name for a black metal band."
                    Mikael said.
                    <br />
                    "Come on…" Aura replied, "Those corpse painted clowns take
                    themselves way too seriously to have a fun band name like
                    that."
                    <br />
                    Simon laughed and said, "Wouldn't it be cool if you had a
                    hardcore-punk band, you would call it Dunn like Ryan."
                    <br />
                    Aura, who just took a gulp of water, had to laugh so loud,
                    she sprayed the water in her mouth all over Aron, who was
                    sitting in front of her.
                    <br />
                    "Looks like we're having rain after all today." Mikael said
                    snorting.
                    <br />
                    "Thanks a lot! "Aron said as he dried his neck and face with
                    a towel. "Well I think you're right about that…" he said
                    looking up into the sky. "Look at those dark clouds up
                    ahead. I'm afraid we'll be driving into bad weather soon."
                </ParagraphStyled>
                <ParagraphStyled>
                    And sure enough, half an hour later the first drops of rain
                    were hitting the windshield of the car. After that it only
                    got worse and worse. As they got near their goal, the rain
                    was coming down in sheets. The rain came down so hard that
                    not even the panic mode of the windshield wipers could
                    provide a clear view anymore. Mikael parked the car on the
                    parking lot of a hotel. <br />
                    "Look, we're nearly there." Mikael said, "But I don't think
                    it's a good idea to go into the forest today. Maybe we
                    should stay in this hotel tonight, and hope the weather will
                    be better tomorrow.”
                    <br />
                    "Sounds like a good idea." Aron said, and the others agreed.
                    <br />
                    Aura looked at the hotel. "It isn't very big, isn't it?" she
                    said.
                    <br />
                    "No. It's not." Mikael replied, looking a bit worried, "So
                    let's hope they got room for us."
                </ParagraphStyled>
                <ParagraphStyled>
                    As they entered the hotel, they were welcomed at the
                    reception by an elderly woman who smiled kindly at them.{" "}
                    <br />
                    "Welcome, how can I help you?" she asked.
                    <br />
                    "We were hoping you got some rooms left." Mikael said. "We
                    want to go camping in the nearby forest, but the weather is
                    so bad, we think it's better to spend the night in your
                    lovely accommodation.
                    <br />
                    "Well, we got two rooms left." the woman said looking a bit
                    doubtful.
                    <br />
                    "Thank chance for small favors." Mikael sighed.
                    <br />
                    "But..." the woman continued, "It's only a single and a
                    double room, and you're a group of four."
                    <br />
                    "No worries." Mikael said smiling, "I presume there is a
                    children's bed in the double room?"
                    <br />
                    "Yes there is..." the woman answered, "But I don't think..."
                    <br />
                    "Well that's perfect!" Mikael interrupted her. "We'll take
                    them. This lovely lady can have the single room.” he said
                    pointing at Aura, "And us three men will take the double.
                    This little gnome would fit perfectly into the children's
                    bed." he said while grabbing Simon. <br />
                    Simon felt angry, but thought it’d be better not to react to
                    this insult.
                    <br />
                    "Well, ok, fine then." the woman said flustered, and she
                    started gathering the necessary paperwork. After they
                    checked in the receptionist led them upstairs.
                    <br />
                    "Dinner is served at seven. There is no menu, you'll just
                    have to eat whatever the cook feels like cooking." she said,
                    as she showed them to their rooms. Aura went into her room
                    and the guys took the room opposite of hers. They had two
                    hours left before dinner, so they all took some time to
                    relax and to have a shower.
                </ParagraphStyled>
                <ParagraphStyled>
                    Aura entered the dining room at seven, and saw that the
                    others already were sitting at a table. As she sat down with
                    them she saw that they were not the only ones in the room.
                    At a table beside them, four local men were drinking beer
                    and talking loudly.
                    <br />
                    "Looks like it's the dining room as well as the the local
                    pub." Mikael said smiling, as he took a sip of his iced tea.
                    "How is your room, madam?" he asked Aura.
                    <br />
                    "Very cozy." Aura replied, "Just enough room to move, but it
                    will do for tonight. How is yours?"
                    <br />
                    Mikael started to laugh, "Well maybe a little bit bigger
                    than yours, but it's still a bit cramped for the three of
                    us. The upside is, Simon actually does fit in the children's
                    bed so we'll do just fine, won't we Bubkins?" he said
                    smiling at Simon. Simon, still looking angry, didn't respond
                    and started eating the dinner that just had been put on the
                    table for them.
                </ParagraphStyled>
                <ParagraphStyled>
                    After dinner they had a conversation with the receptionist
                    about their plans for the next day. <br />
                    "So you're looking for the Svartálfr…" she said, "Not a very
                    good idea. Ten years ago a group of people came here for the
                    same thing, they went into the forest and after that they
                    went missing. Nobody ever heard from them again. Best you
                    leave it be and go party in Reykjavik, that's more fun and
                    much safer."
                    <br />
                    "That's the whole reason we're here." Aron said, "So we'll
                    go into that forest tomorrow, no matter what."
                    <br />
                    "That's it with you young dopeheads, isn't it." one of the
                    locals at the table next to them said. He had apparently
                    listened to their story and started to mingle into their
                    conversation.
                    <br />
                    "Everything for a buzz…" he continued, “You're useless,
                    non-contributing parasites, always looking for the next
                    high. So now your magical cigarettes or funny mushrooms are
                    not enough anymore, and you come here looking for a new kick
                    and polluting our nature while you're at it." His friends
                    all murmured in agreement. All four looked incredulously at
                    the raging local. Simon, who hadn't said a word during
                    dinner, got up because he couldn’t restrain himself any
                    longer.
                    <br />
                    "Always the same isn't it?!" he said mockingly at the
                    locals, "You're so goddamn prejudiced. Come on, you don't
                    even know me. Saying we're parasites and only live to do
                    drugs. For your information we all just graduated in
                    science, maybe we’re just looking for the plant in the name
                    of science, hoping to find a new source to create
                    life-saving medicine. Maybe one of us will save your drunk-
                    asses someday if your liver doesn't work properly anymore
                    and you're in need of medicine created by people like us.
                    You're drinking beer, and lots of it, I've noticed. Alcohol
                    is one of the worst drugs there are, but because everybody
                    is drinking it, and it's legal, it's accepted as normal. If
                    someone enjoys smoking weed or taking some magic mushrooms
                    once in a while, he's the scum of the earth and who are you
                    to judge, it's my fucking life and I'm not bothering anyone.
                    You enjoy your beers you filthy hypocrites, but next time
                    think again before judging people!"
                    <br />
                    Simon got up and walked out of the dining room with a
                    victorious air to him, leaving everybody behind, dumbstruck.{" "}
                    <br />
                    "Well I think I'll go to bed." Mikael said, and he could
                    hardly suppress a smile.
                    <br />
                    Aron and Aura got up too but did not say anything and they
                    all went to their rooms.
                    <br />
                    "That was awesome dude!" Mikael said to Simon as they
                    entered the hotel room. <br />
                    "Well, yeah, people like that make me sick." Simon said, not
                    without a bit of pride. <br />
                    "Let’s just go to sleep." Aron said, "Tomorrow is going to
                    be a long day and we have to get up early if we want to
                    reach the forest before dawn."
                </ParagraphStyled>
                <ParagraphStyled>
                    After breakfast and checking out they all got into the car.
                    It was just a half-hour drive to the place they planned to
                    park the car. As they arrived at the parking place, the sun
                    was already up and shining brightly. They had to hike for a
                    few hours before they would reach the forest. The following
                    hours they all enjoyed the beauties of Iceland's nature. The
                    hills, the streams, the green fields all of which were more
                    beautiful than they had ever seen. At the end of the day
                    they had reached the forest. It was a big forest and the
                    trees and bushes stood very close together, so it was very
                    dark, although the sun still shone.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    At dawn they saw an open space in the forest. <br />
                    "This would be a good place to camp." Aron said as they sat
                    down in the middle of the open space. "We should set up camp
                    quickly, it's getting cloudy again. Some shelter would be
                    nice before it starts raining."
                    <br />
                    Everybody started assembling their tents and after half an
                    hour they were all done. Aura was preparing dinner while the
                    others were scouting the area, hoping they would find the
                    Svartálfr.
                    <br />
                    "I think I found one!" Simon yelled after a while.
                    <br />
                    Aron and Mikael ran to him and laughed.
                    <br />
                    "That's not Svartálfr, dumbass." Mikael sneered at him.
                    <br />
                    "It does look like it…" Aron said and he took out his phone.
                    "Look I downloaded a picture of it, this is the Svartálfr,
                    or at least that’s what the man who took it claims…" <br />
                    He showed the picture to the others.
                    <br />
                    "Well I haven't seen one of those around." Aura said, who
                    joined them. "Never mind that now, dinner is ready, no use
                    searching on an empty stomach now is it."
                </ParagraphStyled>
                <ParagraphStyled>
                    They all sat down in their little camp and enjoyed the food
                    Aura prepared for them. They put their tents in a circle, so
                    they could sit in the middle surrounded by tents. In the
                    middle of dinner, it started to rain, so they were forced to
                    finish their meals inside the tents. Because they were
                    standing in a circle they still could talk to each other
                    with their tent flap open.
                    <br />
                    "This is great…." Mikael said, "We're finally here and we
                    still can't search because of the weather."
                    <br />
                    "It doesn't matter." Aron said. "We're not in a hurry, are
                    we. There is always tomorrow, let’s just enjoy our holiday."
                    and he put some music on, on his phone. The next hour they
                    were talking, listening to music and enjoying themselves.
                    After a while it stopped raining and the moon was shining
                    through the clouds, which were disappearing quickly.{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "I have to take a piss." Simon said and he got up to do so.
                    After a few minutes the group heard Simon shout. "Guys.....
                    Guys, you've got to see this!" <br />
                    Quickly they all got up and went to see what Simon was
                    talking about. As they walked towards Simon they all saw it.
                    It seemed like there were spots of light all over the
                    ground.
                    <br />
                    "Look, it's because of the moon!" Simon said as he pointed
                    to the sky.
                    <br />
                    As Aron took a closer look at one of the dots of light, he
                    saw a plant in the middle of it.
                    <br />
                    "That's the Svartálfr!" he said. "We found it! That's cool,
                    it’s like they're visible now because of the moonlight,
                    pretty weird huh?"
                    <br />
                    "Come on, let's pick them and smoke them!" Simon said
                    excitedly.
                    <br />
                    "What?! Now?" Aron asked doubtfully. "It's already late."
                    <br />
                    "Sure why not?" Simon replied. "That's what we came for
                    isn't it? And who knows, maybe this is the only chance we
                    have. We don't know if we find them again tomorrow."
                    <br />
                    "He's right." Mikael said, "Let's just do it now." <br />
                    Aura, looking both surprised and skeptical, got up, looked
                    at the plant and gave in. With an expression saying ‘sure,
                    why not’, she agreed and they all looked at Aron.
                    <br />
                    "All right." Aron said, "Let's do this."
                </ParagraphStyled>
                <ParagraphStyled>
                    They all went to their tents to get their bongs and returned
                    to the plant, bongs ready.
                    <br />
                    "How do we do this?" Aura asked. "Do we just take the plant,
                    cut it up, and smoke it?"
                    <br />
                    "I read about this." Aron said. "You only smoke the leaves.
                    You have to cut them into small pieces and then mix them up
                    with some tobacco, just like in a joint."
                    <br />
                    "Cool!" Simon said, and he took a knife out of his pocket.
                    He picked some leaves from the Svartálfr and started to cut
                    them into little pieces.
                    <br />
                    "Anyone got any cigarettes?" he asked, and Aura gave him her
                    box of cigarettes. Simon mixed the cut up leaves with the
                    tobacco of a few cigarettes.
                    <br />
                    "There should be enough for all of us." he said, as he
                    filled his bong with the mixture.
                    <br />
                    "Isn't that too much?" Aron asked a bit worried, as he saw
                    Simon fill up his bong.
                    <br />
                    "Well, we’ll just have to find out, won't we…" Simon said,
                    with such a big smile on his face it almost looked scary.
                    They all filled their bongs and sat around the plant.
                </ParagraphStyled>
                <ParagraphStyled>
                    "So this is it." Aron said, "We're really doing this then."
                    <br />
                    "Yep!" Simon said and he fired up his bong. He took a large
                    shot, inhaled it, and held his breath. When he exhaled, he
                    started to cough badly and didn't stop for a minute.
                    <br />
                    "Wow, are you ok?" Aura asked him.
                    <br />
                    "Yeah, I'm fine." Simon said after he regained his breath.
                    "This actually tastes good, come on guys, you have to try
                    this."
                    <br />
                    Mikael and Aura took their lighters and started smoking from
                    their bongs. As the last of their ration of the plant had
                    filled their lungs they started to cough too. Aron watched
                    them with his bong in his hand.
                    <br />
                    "Come on dude!" Mikael exclaimed, "Your turn! We all smoked
                    it…"
                    <br />
                    Still doubtful, Aron looked at his bong and he knew he had
                    to smoke it. <em>Come on</em>, he thought
                    <em>, this is what you wanted, so do it</em>.<br />
                    He took his bong, lit it up and smoked the Svartálfr. After
                    he finished coughing he looked at the others.
                    <br />
                    "So it begins…" he said.
                </ParagraphStyled>
                <ParagraphStyled>
                    <strong>Simon's trip</strong>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>And let's hope it does so quickly</em>, Simon thought.
                    After finishing his last shot he got up. <br />
                    "Not feeling anything yet." he said to the others, and he
                    walked away from them. After a few meters he stopped and
                    looked up to the sky and stared at the moon for a while. He
                    continued walking and stopped at a large tree, observing it
                    for a while and walked on. <em>This sucks, </em>he thought
                    to himself. <br />
                    <br />
                    "Guys, any of you feeling anything right now?" he asked. But
                    as he turned around he didn't see the others anymore.
                    Instead he saw himself, sitting down and smoking his bong
                    where he just sat with the others. He also saw himself
                    standing, staring at the moon and a bit further at the large
                    tree. It was like he left multiple Simon-prints in the
                    forest.
                    <br />
                    <em>Wow this is weird, </em>he thought,{" "}
                    <em>maybe it is working</em>, and he smiled. C
                    <em>ool! Ok, let's take a trip then!</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    At that moment he noticed a tiny white dot of light jumping
                    from tree to tree. He followed it to a large rock. The dot
                    of light shone onto the rock and Simon watched it with much
                    interest. He felt great. The plant made him feel the same
                    way he did when he got high on XTC, his favorite drug, only
                    he was more focused now. The light split up into two dots of
                    light, the second one shining two meters above the other.
                    After that both dots of light split up a meter sideways and
                    lines were appearing, connecting the four dots, thus
                    creating a rectangular shape. Simon was watching the
                    process, mesmerized. Not long after that the rectangle
                    turned into a door.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>Sweet, a door! Let's see if it opens.</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Simon tried to open the door by pushing it , but it didn't
                    budge. Then he saw a door knocker in the shape of a wolf's
                    head and he used it to knock on the door. Immediately the
                    door opened. Simon looked inside but all he saw was
                    darkness. He hesitated. <em>Should I enter?</em> But his
                    curiosity was stronger than his fear, so he entered. After
                    he entered, the door swung shut behind him and he fell into
                    a hole. He fell several meters and landed onto a big chair.
                    Bewildered he looked around. He saw he was in a cave
                    equipped as a living room. "Ah, there you are, we were
                    expecting you!" he heard a sweet voice say.
                    <br />
                    Simon looked up and saw two pretty girls walking at him and
                    before he knew what was happening they sat on his lap. He
                    looked around and saw that there was a party going on and it
                    seemed like everybody was smiling at him and that they were
                    glad he finally arrived. He felt better than he ever felt
                    before. <br />
                    "Now it's a party!" one of the girls said to him. "Now close
                    your eyes and let me and my girl here please you like nobody
                    has ever pleased you before…"
                    <br />
                    <em>Yeah!,</em> Simon thought as he closed his eyes and felt
                    his zipper being opened.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Thief!!" he heard a deep voice say. He opened his eyes and
                    looked down and saw the girls were gone. Simon didn't feel
                    so great anymore. He tried to get up but he couldn't move
                    his body anymore. He looked up and stared into two big
                    bright white eyes.
                </ParagraphStyled>
                <ParagraphStyled>
                    <strong>Aura's trip</strong>{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    "Not feeling anything yet." Simon said after he got up, and
                    he walked away from Aura and the others. Aura took the last
                    shot from her bong and observed Simon, how he was staring at
                    the moon.
                    <br />
                    "Look at him…" she said to the others, "Saying he doesn't
                    feel anything yet. He's staring up at the sky like he’s on
                    mushrooms."
                    <br />
                    The two guys ignored her and continued talking to each
                    other. <br />
                    "Guys, any of you feeling anything right now?" she asked
                    Mikael and Aron, but the two of them still ignored her and
                    got on with their conversation. <br />
                    "Hey, that's pretty rude…" she said a bit angry, "Pretending
                    I'm not here." She tried to punch Mikael on the shoulder,
                    but instead of hitting him her hand went right through him
                    like he was a ghost.
                    <br />
                    <em>Wow that's weird, </em>she thought, and she got up to
                    hit Aron on his head, but the same thing happened and her
                    hand went through Aron's head like it was made of smoke.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        Awesome, this stuff is really strange, never had a trip
                        like this. Cool.
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    She got up and decided to take a walk, to see how the trip
                    would evolve. She walked for quite some time and she felt
                    great. The trip felt like she was on a mushroom trip, one of
                    her favorite kinds of drugs, only she was more focused now.
                    She arrived at a little stream and she watched it streaming
                    down to God knows where. She was fascinated by it and lost
                    track of time.
                    <br />
                    "Aura... Aura…" She heard a voice say. It was the sweetest
                    voice she had ever heard, and she looked around where the
                    voice came from. Behind a large tree she saw a woman in a
                    white dress. It was the most beautiful woman she had ever
                    seen. The woman had such a big attraction to her, that she
                    couldn't stop herself from walking straight at her. The
                    woman disappeared behind the large tree and Aura started to
                    run at the tree, hoping the woman would still be there. As
                    she arrived at the tree Aura didn't see her anymore.
                    Disorientated she looked around.
                </ParagraphStyled>
                <ParagraphStyled>
                    "There you are…" Aura heard behind her, "I was expecting
                    you." <br />
                    Aura turned around and saw the woman in white standing right
                    behind her. The woman took Aura's head between her hands and
                    started kissing her. It was the best feeling she ever had.{" "}
                    <br />
                    "Thief." the woman said.
                    <br />
                    "What?!" Aura asked, but before she could say more the woman
                    kissed her again. Aura closed her eyes and felt a kind of
                    bliss she had never felt before.
                    <br />
                    "Thief!!" The woman said again, but this time in a much
                    deeper voice which scared Aura, and made her feel very
                    uncomfortable. She tried to move but it felt like she was
                    nailed to the ground. Aura opened her eyes and stared into
                    two big bright white eyes.
                </ParagraphStyled>
                <ParagraphStyled>
                    <strong>Mikael's trip</strong>
                </ParagraphStyled>
                <ParagraphStyled>
                    "Not feeling anything yet." Mikael heard Simon say, and he
                    saw him get up to take a walk.
                    <br />
                    "What do you expect." he said to Simon, "You've just
                    finished smoking. Give it some time." but Simon didn't react
                    anymore. He looked at Aron and frowned at him. <br />
                    "Look at him, complaining it doesn't work, yet he's staring
                    at the sky like some dopehead who just took too much drugs.
                    Hey, did you see where Aura went? She was just sitting next
                    to me."
                    <br />
                    Aron shrugged, "Don't know. She's probably taking a piss."
                    <br />
                    "Ok, she'll be back," Mikael said, "Now let’s wait and see
                    on what journey this lovely plant is going to take us."{" "}
                    <br />
                    That moment Mikael shivered, "Wow, what the fuck was that?"
                    he said, and Aron looked at him wondering what he was
                    talking about.
                    <br />
                    "It felt like someone touched me, only more intense."
                    <br />
                    "It's probably the drugs." Aron said grinning, but at the
                    same time he too shivered. <br />
                    "Hey, I felt something too, still think it's the plant
                    starting to work, though."
                    <br />
                    Mikael looked at him, "So, You're feeling anything yet?"
                    <br />
                    "Hcum ton." Aron said.
                    <br />
                    "What?" Mikael asked.
                    <br />
                    "Tey, hcum gnileef ton m'I." Aron said.
                    <br />
                    Mikael stared at Aron, "Are you fucking with me?"
                    <br />
                    "On, tahw", Aron said, and he looked at Mikael, a bit
                    worried.
                    <br />
                    "Fuck this." Mikael said, "I don't have time for your
                    childish games. I'm going to take a walk too. Have a good
                    one." He got up and walked away from Aron.
                    <br />
                    "Tiaw!" he heard Aron say, but he had made up his mind. He
                    preferred to have this trip on his own.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>That was weird, </em>he thought,{" "}
                    <em>this shit must be kicking in.</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    He walked on for a few minutes and he felt great. The trip
                    felt like he was on a FM-WL trip,&nbsp; lately one of his
                    favorite drugs, only he was more focused now. He stopped to
                    take a look around, and he saw that he was standing in an
                    open space in the forest surrounded by a circle of trees. He
                    looked at the trees and saw the strangest thing: All over
                    the trees there were clocks, hanging in the branches, but
                    they were turning backwards.It gave Mikael a blissful
                    feeling. Ever since his father died when he was ten years
                    old, he had a problem with ageing, seeing time go forward.
                    So this felt strangely pleasant. Suddenly it felt like the
                    trees were growing, and he heard a voice.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Mikael!" it was a familiar voice, he hadn't heard in a long
                    time. <br />
                    He turned around and saw a man standing behind him. <br />
                    "Dad?" he said in shock. <br />
                    "I've been expecting you son." <br />
                    His dad picked him up and held him up into the air. <br />
                    That was when he noticed that it wasn’t the trees that were
                    growing, but him turning into the ten year old boy he was
                    when his father died.
                    <br />
                    "I missed you dad!" he said with tears in his eyes.
                    <br />
                    "I missed you too son, you know you were always my
                    favorite... thief!"
                    <br />
                    "Tick tock, Mikael!" his father said and he put him back on
                    the ground.
                </ParagraphStyled>
                <ParagraphStyled>
                    Mikael didn't feel so great any more. He tried to get away
                    but it felt like his feet were stuck in the ground and he
                    could not move anymore. He looked at the clocks and saw them
                    running forward, fast, some of them melting on the branches,
                    like in a Salvador Dali painting.
                    <br />
                    "THIEF!!" he heard again, but this time in a much deeper
                    voice. Mikael looked at his father and stared into two big
                    bright white eyes.
                </ParagraphStyled>
                <ParagraphStyled>
                    <strong>Aron's trip</strong>
                </ParagraphStyled>
                <ParagraphStyled>
                    "So, You're feeling anything yet?" Mikael asked. <br />
                    "Not much." he replied.
                    <br />
                    "What?" Mikael asked.
                    <br />
                    "I'm not feeling much yet." Aron said and saw that Mikael
                    was staring at him.
                    <br />
                    "Are you fucking with me?" Mikael asked, sounding a bit
                    irritated.
                    <br />
                    "What? No." Aron said looking at Mikael, a bit worried.
                    <br />
                    "Fuck this." Mikael said, "I don't have time for your
                    childish games. I'm going to take a walk too. Have a good
                    one."{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    As Mikael got up he walked away backwards and Aron could
                    swear the last sentence he said was with the voice of a
                    little child. <br />
                    “Wait!” Aron shouted after Mikael, but it was no use. As he
                    saw him disappear into the forest he could swear Mikael had
                    turned into a little boy.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>Weird</em>, he thought,{" "}
                    <em>
                        the plant is starting to do its job apparently, cool.
                    </em>
                    <br />
                    <em>
                        So, everyone is having their trip alone, that's fine.
                        It's been a while, I had a trip alone. Just me, the
                        trip, and good music. This is going to be awesome.
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    He walked back to his tent. He felt great. The trip felt
                    like he was on LSD,&nbsp; one of his favorite kinds of
                    drugs, only he was more focused now. He took his headphones
                    out of his backpack and plugged it into his phone. <br />
                    <em>Now let's see, a b c d .... p, there you are</em>,
                    scrolling through his music files he found what he was
                    looking for.{" "}
                    <em>
                        Ah, Dark Side of the Moon, my old friend. Let’s go for
                        one more.
                    </em>
                    <br />
                    <br />
                    After he listened to the whole album and enjoying the trip,
                    he played it again. And again. And again. Aron had never
                    enjoyed listening to Pink Floyd more than he had sitting
                    alone at that camping site. He enjoyed the album so much
                    that he kept on repeating it every time it had finished. To
                    Aron it seemed he had listened to the album a thousand
                    times. Not metaphorically, but an actual thousand times. So
                    he was sitting there, listening to the Dark Side of the Moon
                    for days and days in his perception without getting bored
                    with it.{" "}
                    <em>
                        Cool, this must be what that guy in Groundhog Day must
                        have felt like
                    </em>
                    , Aron chuckled to himself. The one thousand and first time
                    he listened to the song "Time" he heard a deep voice say,{" "}
                    <br />
                    "Yes it's time, thief! Sadly enough I've been expecting
                    you."
                </ParagraphStyled>
                <ParagraphStyled>
                    Aron looked up and saw the thing which had been haunting him
                    in his dreams and during his mushroom trip. <br />
                    "I warned you!!" the thing said, "To leave my plants alone!
                    And still you come here, and steal them from me! Thief!!"
                </ParagraphStyled>
                <ParagraphStyled>
                    Aron did not feel so great anymore. He tried to get up, but
                    he noticed he could not move at all. He looked back at the
                    beast and stared into two big bright eyes.
                </ParagraphStyled>
                <ParagraphStyled>
                    <strong>One year later</strong>{" "}
                </ParagraphStyled>
                <ParagraphStyled>
                    He hadn't changed much, maybe his skin had a darker tan due
                    to a year's worth of sunlight. Still wearing a Black Sabbath
                    shirt, with long curly hair coming out underneath his all-
                    blacks baseball cap. Frey just came back after he had spent
                    a year in New Zealand. As he got home he sat down on his
                    couch and called Aron.
                    <br />
                    "This number is disconnected." he heard after dialing Aron's
                    number.
                    <br />
                    <em>Great, </em>he thought, <em>let's try the others.</em>{" "}
                    But he got the same result with Aura's, Mikael's, and
                    Simon's number.
                    <br />
                    <em>What the hell, better call Aron's mother then.</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    He called Aron's mother whom he had known since he was a
                    child. <br />
                    "Hi, it's Frey." he said, after Aron's mother picked up the
                    phone, "I can't get hold of Aron and the other three. Looks
                    like they all changed their number since I went away!" he
                    said laughing.
                    <br />
                    "Oh, no. Didn't you hear my dear?" Aron's mother said.
                    <br />
                    "No I heard nothing. When I went to New-Zealand I decided to
                    leave my phone at home and use no Facebook or other social
                    media. I just wanted to really go away for a year, call it a
                    black sabbatical." he said with a smile. "Why, what
                    happened?" he asked, more seriously.
                    <br />
                    "You better come over here, love…" Aron's mother said.
                </ParagraphStyled>
                <ParagraphStyled>
                    He hung up the phone and got up to go to Aron's mother. She
                    lived only a few blocks away so he decided to walk over
                    there. It was a sunny day, but Frey didn't notice. The whole
                    way he was worried about what might have happened. As he
                    arrived at Aron's mother's house she was already standing in
                    the doorway. After hugging each other they went inside. They
                    sat down at the kitchen table. <br />
                    "As you know… They went to Iceland." Aron's mother began,
                    and she started to cry.
                    <br />
                    <em>This can't be good,</em> he thought and put his arm
                    around her shoulders.
                    <br />
                    "They went missing…" she said sobbing, "They went to some
                    forest searching for some stupid plant and they never came
                    back. Nobody heard from them since. The authorities have
                    been searching for weeks but they couldn't find them. I've
                    talked to Aron's cousin, Jón, he helped searching but it was
                    in vain. I will never see my baby boy again!" <br />
                    She started to cry even harder and Frey just held her. He
                    couldn't believe it.
                    <br />
                    "I must go there!" he said.
                    <br />
                    "They've searched every inch of that forest for weeks, it's
                    no use."
                    <br />
                    "I don't care!" Frey said determined. "I have to go there
                    and look for them myself, I owe them that much, they're my
                    friends. I should have been there!"
                    <br />
                    Aron's mother tried to talk Frey out of it but it was no
                    use, he had made up his mind. <br />
                    "Here is Jón's number, if you must go, give him a call. He
                    will tell you where they went. He took the number said
                    goodbye and went home.
                </ParagraphStyled>
                <ParagraphStyled>
                    He booked a flight to Iceland for the next day. He called
                    Jón and told him about his plans. Jón agreed to pick him up
                    from the airport the next day. That night he couldn't sleep.
                    Jet lag and worrying isn't the right recipe for a good
                    night’s sleep. The plane landed in Reykjavík late in the
                    afternoon.
                </ParagraphStyled>
                <ParagraphStyled>
                    "You must be Frey." Jón said with his hand held out.
                    <br />
                    "Thank you for helping me." Frey said.
                    <br />
                    "Sure, anything for Aron's friends." Jón said, "But first we
                    go to my place. You look like you could use some sleep.
                    We'll go to the forest first thing tomorrow. It's a long
                    drive and we have to walk for a few hours so we'll bring
                    camping gear so we can spend the night in the forest."
                </ParagraphStyled>
                <ParagraphStyled>
                    The next morning they got up early, had breakfast and drove
                    off to the forest.
                    <br />
                    "You're not going to find them." Jón said," We've been
                    looking for weeks without any results."
                    <br />
                    "Why are you coming with me then?" Frey asked.
                    <br />
                    "Some part of me still has hope, I think." Jón said
                    unconvincingly.
                    <br />
                    "How do you know for sure into which forest they went?" Frey
                    asked.
                    <br />
                    "Well that was more than an educated guess…" Jón answered,
                    "The police found their tents with all their belongings in
                    them."
                </ParagraphStyled>
                <ParagraphStyled>
                    After a few hours of driving and even more hours of walking
                    they arrived at the forest. As they got to an open space in
                    the forest, Jón indicated that they had arrived at the spot
                    where the tents were found. It already started to get dark,
                    so they decided to set up camp and go to bed.
                    <br />
                    "We go to sleep early and get up at the crack of dawn, so we
                    got the whole day to search this forest." Jón said, and
                    after finishing dinner they both went inside their tents.
                    Frey sat down on his air mattress and took a map of the
                    forest out of his backpack, which had been given to him by
                    Jón. He wanted to search the map for obvious places to look
                    for any signs, his friends could have left before they
                    disappeared.
                </ParagraphStyled>
                <ParagraphStyled>
                    "That was a weird trip!" Aron said to Frey.
                    <br />
                    Five friends were sitting in the same field just outside of
                    Trondheim, more than a year later. <br />
                    "Yeah!" Simon said with a smile on his face, "Nice of you to
                    come looking for us, and we're very sorry we made you go all
                    the way to Iceland for nothing."
                    <br />
                    Mikael looked at Frey and said, "That was the weirdest trip
                    we've ever had. We totally lost track of time for months. We
                    wandered around Iceland's nature without seeing other people
                    and without realizing our family and friends were worried
                    sick about us."
                    <br />
                    <em>That doesn't make sense, </em>Frey thought, but didn't
                    give the subject any more thought. He was just glad to see
                    his friends again, alive and kicking. Aura just sat in the
                    grass enjoying the sun not saying anything. The moment Frey
                    looked at her she said, "You don't belong here, be gone."
                    <br />
                    "What?" Frey asked a bit taken aback.
                    <br />
                    "They're with me now, be gone and don't come back!" <br />
                    As Aura spoke these words her eyes turned into bright white
                    ones and her face turned into that of a monster. It scared
                    the shit out of Frey. He took a deep breath and found
                    himself sitting upright in his tent in the forest again. It
                    took him some time to realize he had a bad dream and that he
                    actually still was in his tent in Iceland.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        Damn, that sucked. The stress of them being lost must
                        have really gotten to me
                    </em>
                    , he thought.
                </ParagraphStyled>
                <ParagraphStyled>
                    It was the middle of the night and his bladder was signaling
                    to him to find him a nice tree to relieve it from its
                    burden. He got up, out of his tent to do so. As he was
                    peeing he had the strange feeling someone or something was
                    watching him. The sky was clear and the moon was full. After
                    he finished he thought he heard a sound. It sounded like a
                    deep growl to him, just like from a wild animal. He turned
                    around to where the sound came from, on high alert. He saw
                    nothing move nor did he see an animal. He did see a rock
                    formation which he could have sworn wasn't there before. It
                    took all his bravery to walk towards the rocks, for he was
                    strangely pulled towards them. The closer he got the more
                    scared he got, but he was also more drawn to it. There, that
                    deep growling again. <em>What the hell am I doing,</em> he
                    thought,
                    <em> I should be running like hell, far away from here.</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    As he arrived at the rock formation his heart skipped
                    several beats. Could this be real or another fucked up
                    nightmare? He walked around it not believing what he was
                    seeing. The rock formation consisted the shapes of four
                    persons, persons quite familiar to Frey.{" "}
                    <em>This is not real, </em>he thought and a shiver went down
                    his spine.
                </ParagraphStyled>
                <ParagraphStyled>
                    What he saw were statues of all four of his lost friends. It
                    looked like Aura, Aron, Mikael and Simon had turned to
                    stone, and by the looks on their faces the last thing they
                    saw must have been something horrifying. That moment all
                    Frey wanted to do is run like hell. The minute he wanted to
                    turn around he saw a face of a monster with big bright eyes.
                    "I told you to be gone, they're with me now and there is
                    nothing you can do to change that!" the beast said. "They
                    stole from me although I warned them. They are cursed to
                    stay with me forever..."
                </ParagraphStyled>
                <ParagraphStyled>
                    "Now be gone!!" the beast shouted at Frey in his low
                    growling voice.
                    <br />
                    Frey, scared as hell, turned around and started running.
                    Within seconds, all went black.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Dude, are you ok?"
                    <br />
                    Frey opened his eyes. His head hurt pretty bad. He looked up
                    and stared into the eyes of Jón. <br />
                    "What the fuck dude?!" Jón said, "Did you not see this
                    branch or something, how long have you been laying here?"
                    <br />
                    Frey got up and noticed he stood right beneath a big branch.
                    He must have walked right into it the moment he wanted to
                    run away from the monster, he thought. Frey remembered
                    everything that happened like it just happened minutes ago.
                    The sun was already up and as he turned around the rock
                    formation was gone, just like it had never even been there
                    before. Frey started explaining to Jón what had happened.
                    The more Frey told, the more disbelievingly Jón stared at
                    him. <br />
                    "Dude you must have hit that branch pretty hard." Jón said
                    after Frey finished his story.
                    <br />
                    "I don't care if you believe me or not!" Frey said, "All I
                    want to do now is get the hell out of here."
                    <br />
                    "But we wanted to search for them today…" Jón said not
                    believing what he was hearing.
                    <br />
                    "We have to go! Right now!" Frey shouted at Jón, "They're
                    not here anymore! At least not alive... I know now."
                    <br />
                    "Ok, whatever, sure thing man, we'll go right now…" Jón
                    said, after seeing how serious Frey was about leaving.
                </ParagraphStyled>
                <ParagraphStyled>
                    It took them a while before they finished packing all their
                    stuff. The moment they left to go back to the car, the
                    weather had changed and storm clouds covered the sky. As
                    they walked away, a being followed their every move with
                    bright white eyes.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>Good, and stay gone</em>.
                </ParagraphStyled>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StorySvartalfr;
