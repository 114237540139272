import React, { useReducer, createContext } from "react";
import { useHistory } from "react-router-dom";
import { accountReducer, initialState } from "./reducers/accountReducer";
import httpClient from "../auth/httpClient";
import { protectedRoutes, routes } from "../routes";

export const AccountContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const AccountProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const history = useHistory();

    const login = async (loginCredentials) => {
        dispatch({ type: "SET_LOADING" });
        try {
            const result = await httpClient.post(
                "/api/auth/login",
                loginCredentials
            );

            localStorage.setItem("userName", result.data.name);
            localStorage.setItem("userEmail", result.data.email);
            localStorage.setItem("access_token", result.data.access_token);
            localStorage.setItem("userLoggedIn", true);

            dispatch({ type: "LOGIN", payload: result.data });

            history.replace({ pathname: protectedRoutes.adminDashboard });
        } catch (error) {
            dispatch({ type: "LOGIN_ERROR", payload: error });
        }
    };

    const logout = async () => {
        const result = await httpClient.post("/api/auth/logout");

        localStorage.getItem("userName") && localStorage.removeItem("userName");
        localStorage.getItem("userEmail") &&
            localStorage.removeItem("userEmail");
        localStorage.getItem("access_token") &&
            localStorage.removeItem("access_token");
        localStorage.getItem("userLoggedIn") &&
            localStorage.removeItem("userLoggedIn");

        window.location.reload();
    };

    const checkAdminPermission = async () => {
        try {
            await httpClient.get("/api/auth/me");
        } catch (error) {}
    };

    return (
        <AccountContext.Provider
            value={{
                account: state.account,
                loading: state.loading,
                error: state.error,
                login,
                logout,
                checkAdminPermission,
            }}
        >
            {children}
        </AccountContext.Provider>
    );
};
