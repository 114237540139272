
export const initialState = {
    loading: true,
    error: null,
    shows: [],
}

export const showsReducer = (state, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return {
          loading: action.payload,
          ...state
        }
      case "FETCH_LIST":
        return {
          loading: false,
          error: null,
          shows: action.payload
        }
      case "FETCH_LIST_ERROR":
        return {
          loading: false,
          error: 'Could not fetch shows list',
          shows: []
      }
      case "ADD_SHOW":
        return {
          loading: false,
          error: null,
          shows: [...state.shows, action.payload]
        }
      case "UPDATE_SHOW":
        return {
          loading: false,
          error: null,
          shows: state.shows.map((show) => {
            return (show.id == action.payload.id) ? action.payload : show;
          })
        }
      case "DELETE_SHOW":
        return {
          loading: false,
          error: null,
          shows: state.shows.filter((show) => show.id != action.payload.id)
        }
      case "MUTATION_ERROR":
        return {
          loading: false,
          error: action.payload,
          shows: state.shows
        }
 
      default:
        return state
    }
}
