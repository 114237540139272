import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(400px, 75%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function DeleteModal({
    open,
    handleClose,
    onSubmit,
    titleSingle,
    entityToDelete,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        onSubmit(entityToDelete).finally(() => {
            setIsLoading(false);
            handleClose();
        });
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ maskBorderSlice: 2, marginBottom: 2 }}
                            >
                                {`Delete ${titleSingle} with ID: ${entityToDelete.id}?`}
                            </Typography>
                            <FormControl sx={{ minWidth: "100%" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginTop: "8px",
                                    }}
                                >
                                    <Button
                                        onClick={handleClose}
                                        variant="contained"
                                        color="primary"
                                        sx={{ minWidth: "40%" }}
                                    >
                                        Cancel
                                    </Button>
                                    {isLoading ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: "40%" }}
                                        >
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: "black",
                                                }}
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            sx={{ minWidth: "40%" }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            </FormControl>
                        </Box>
                    </Fade>
                </form>
            </Modal>
        </div>
    );
}
