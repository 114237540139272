
export const initialState = {
    loading: true,
    error: '',
    users: [],
}

export const usersReducer = (state, action) => {
    switch (action.type) {
      case 'SET_LOADING':
        return {
          loading: true,
          ...state
        }
      case "FETCH_LIST":
        return {
          loading: false,
          error: '',
          users: action.payload
        }
      case "FETCH_LIST_ERROR":
        return {
          loading: false,
          error: 'Could not fetch users list',
          users: []
      }
      case "ADD_USER":
        return {
          loading: false,
          error: '',
          users: [...state.users, action.payload]
        }
      case "UPDATE_USER":
        return {
          loading: false,
          error: '',
          users: state.users.map((user) => {
            return (user.id == action.payload.id) ? action.payload : user;
          })
        }
      case "DELETE_USER":
        return {
          loading: false,
          error: '',
          users: state.users.filter(user => user.id != action.payload.id)
        }
      case "MUTATION_ERROR":
        return {
          loading: false,
          error: 'Could not perform operation',
          ...state
        }
 
      default:
        return state
    }
}
