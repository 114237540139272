import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { SettingsContext } from "./../context/SettingsContext";
import Typography from "@mui/material/Typography";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import layer1 from "./audio/layer1.mp3";
import layer2 from "./audio/layer2.mp3";
import layer3 from "./audio/layer3.mp3";
import layer4 from "./audio/layer4.mp3";
import layer5 from "./audio/layer5.mp3";
import layer6 from "./audio/layer6.mp3";

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.alignment ? props.alignment : `center`)};
    margin: 5px 0;
    padding: 0 20px;
`;

const StyledButtonContainer = styled.div`
    margin: 20px;
`;

const SettingsSection = () => {
    const { setStoryFontSize, storyFontSize } = useContext(SettingsContext);
    const [audioPlaying, setAudioPlaying] = useState(false);

    const audioLayer1 = useRef(new Audio(layer1));
    audioLayer1.current.loop = true;
    const audioLayer2 = useRef(new Audio(layer2));
    audioLayer2.current.loop = true;
    const audioLayer3 = useRef(new Audio(layer3));
    audioLayer3.current.loop = true;
    const audioLayer4 = useRef(new Audio(layer4));
    audioLayer4.current.loop = true;
    const audioLayer5 = useRef(new Audio(layer5));
    audioLayer5.current.loop = true;
    const audioLayer6 = useRef(new Audio(layer6));
    audioLayer6.current.loop = true;

    const handleStoryFontSizeChange = (event) => {
        setStoryFontSize(event.target.value);
    };

    const togglePlayAudio = () => {
        setAudioPlaying(!audioPlaying);
    };

    useEffect(() => {
        if (audioPlaying) {
            audioLayer1.current.play();
            audioLayer2.current.play();
            audioLayer3.current.play();
            audioLayer4.current.play();
            audioLayer5.current.play();
            audioLayer6.current.play();
        } else {
            audioLayer1.current.pause();
            audioLayer2.current.pause();
            audioLayer3.current.pause();
            audioLayer4.current.pause();
            audioLayer5.current.pause();
            audioLayer6.current.pause();
        }
    }, [audioPlaying]);

    return (
        <>
            <SettingsContainer>
                <Typography variant="h6" sx={{ padding: "3px 0" }}>
                    Settings
                </Typography>
            </SettingsContainer>
            <Divider sx={{ margin: "5px 0 20px 0" }} />
            <SettingsContainer>
                <Typography variant="subtitle1">
                    Give meaning to life
                </Typography>
                <StyledButtonContainer>
                    {audioPlaying ? (
                        <IconButton
                            onClick={() => {
                                togglePlayAudio();
                            }}
                        >
                            <PauseCircleOutlineIcon
                                sx={{
                                    height: "50px",
                                    width: "50px",
                                    cursor: "pointer",
                                }}
                            />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => {
                                togglePlayAudio();
                            }}
                        >
                            <PlayCircleOutlineIcon
                                sx={{
                                    height: "50px",
                                    width: "50px",
                                    cursor: "pointer",
                                }}
                            />
                        </IconButton>
                    )}
                </StyledButtonContainer>
            </SettingsContainer>
            <Divider sx={{ margin: "20px" }} />
            <SettingsContainer>
                <Typography variant="subtitle1">Set Story Font Size</Typography>
            </SettingsContainer>
            <SettingsContainer alignment={"baseline"}>
                <FormControl>
                    <RadioGroup
                        value={storyFontSize}
                        onChange={handleStoryFontSizeChange}
                    >
                        <FormControlLabel
                            sx={{ height: "30px" }}
                            value="larger"
                            control={<Radio />}
                            label="Large"
                        />
                        <FormControlLabel
                            sx={{ height: "30px" }}
                            value="inherit"
                            control={<Radio />}
                            label="Normal"
                        />
                        <FormControlLabel
                            sx={{ height: "30px" }}
                            value="smaller"
                            control={<Radio />}
                            label="Small"
                        />
                    </RadioGroup>
                </FormControl>
            </SettingsContainer>
            <Divider sx={{ margin: "20px" }} />
        </>
    );
};

export default SettingsSection;
