import React from "react";
import BackButton from "../components/BackButton";
import { routes } from "../routes";
import {
    StoriesLayout,
    StoryHeaderStyled,
    StorySubHeaderStyled,
    ParagraphStyled,
} from "../components/StoryComponents";
import MainPageLayout from "../components/MainPageLayout";

const StoryUmibozu = () => {
    return (
        <MainPageLayout>
            <StoriesLayout>
                <StoryHeaderStyled>
                    <BackButton route={routes.reliqideus} y={0} />
                    Umibozu
                </StoryHeaderStyled>
                <StorySubHeaderStyled>Nagasaki june 1824</StorySubHeaderStyled>
                <ParagraphStyled>
                    <em>
                        So it had come to this. Shimizu Miyamoto had turned his
                        back on his safe haven. And now he was here, seeking
                        refuge in this perilous place. He couldn't help but
                        smile at the irony of it.
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    As he had left home he had had no idea what was to become of
                    him. Before, his whole life had been planned out for him and
                    there had been no uncertainties, but now his future was
                    ambiguous. As a bastard son of the shogun he would have had
                    a carefree life. Had he made the right decision or had he
                    thrown it all away for something much worse? But everyone
                    created their own purgatory. His couldn't be worse than
                    anyone else's. And how could he regret his actions? He had
                    not been able to bear living under his father's feudal
                    regime any longer. So he had decided to come here, to
                    Dejima, an artificial island in the harbor of Nagasaki that
                    was the only connection to the Western world in Japan, to
                    look for a vessel that would take him to Europe, as far away
                    from this dreadful country and its atrocious leaders as
                    possible.
                </ParagraphStyled>
                <ParagraphStyled>
                    There were only a few offices, houses, and warehouses on
                    Dejima itself, so Shimizu had had to return to Nagasaki
                    harbor, across the only bridge on the small island, to gain
                    information. It was late in the evening as he walked passed
                    what seemed like a marketplace. It was abandoned, but he
                    smelled the pungent stench of rotting fish intestines that
                    had been left behind by the salesmen, and he could still see
                    blood meandering through the cracks in the street. He was
                    looking for a tavern, where he would hopefully find a
                    European trader who would aid him in his needs.
                </ParagraphStyled>
                <ParagraphStyled>
                    He walked onto a dock and stopped as his gaze floated over
                    the sea. It was restless all the way to the horizon, but
                    somehow strangely appealing to him. He watched the foamy
                    waves hit the shore and he felt a sense of freedom like
                    never before. After strolling the streets for a while and
                    making some inquiries, he found what he was looking for. In
                    a small, murky-looking alley there was a sign on the wall
                    that read The Watering Monk. A bit of a strange name for a
                    tavern, Shimizu thought, but then again, this wasn't the
                    kind of establishment he was used to visiting in his former
                    life. As he proceeded to the entrance, he swung away just in
                    time to dodge a man storming out of the bar. The man fell on
                    his knees, started liberating his stomach from whatever foul
                    products he'd been poisoning it with the last couple of
                    hours, and then crumpled to the ground, insensible, his head
                    in his own vomit. Shimizu froze and stared at this, to his
                    mind, somewhat bizarre occurrence. He pulled himself
                    together and continued to the entrance.
                </ParagraphStyled>
                <ParagraphStyled>
                    As he opened the heavy wooden door, a variety of scents
                    welcomed him, he saw a thick layer of smoke hanging in the
                    whole room, and there was a cacophony of sounds. For a
                    moment he felt reluctant to enter but continued nonetheless.
                    He could not and would not turn away from this now that he
                    was so close to unchaining himself. He took a seat at the
                    shabby-looking bar and ordered a drink.
                </ParagraphStyled>
                <ParagraphStyled>
                    "You look a bit out of place in a stink hole like this,” the
                    man next to him grunted. “Does your mother know you're
                    here?" Shimizu looked at him. The man was missing one eye
                    and hadn’t bothered hiding it with a patch, so there was a
                    nasty-looking hole where his right eye used to be. She
                    doesn't actually, Shimizu thought, but chose to ignore the
                    drunken fisherman. When the taverner returned with his
                    drink, Shimizu asked him if he knew a European trader who
                    would be sailing back home anytime soon. "Not many,
                    especially in this kind of weather," the taverner said, and
                    pointed out a roaring and mostly drunk-looking group of men.
                    "But maybe you're in luck. That crowd over there is setting
                    sail tomorrow morning."
                </ParagraphStyled>
                <ParagraphStyled>
                    Shimizu emptied his glass in one rapid tilt, set it on the
                    bar, thanked the barman, and started walking towards the
                    noisy group of sailors. As he arrived at their table, one of
                    the drunken sailors shouted something to him in a foreign
                    language. A bit taken aback, he asked, in his best English,
                    who the commander of this group was. "That would be me!" a
                    towering, muscular man said. He got up and Shimizu instantly
                    felt like he had just shrunk a few inches on the spot.
                    "What's it to you?" the man asked, his pocked face hovering
                    over Shimizu. "My name is Shimizu Miyamoto, and I was
                    wondering if you could take me with you on your way back to
                    Europe.” The tall man looked at Shimizu, studying him for a
                    while, then he took one of Shimizu's hands and started
                    laughing. “Yes we got room for one more," the man said with
                    a smirk on his face "but you will have to work to earn your
                    place and these hands don't look like they've seen much
                    labor in their sad excuse for a life. I'll cut of my left
                    nostril if it's been more than one day!" Shimizu thought
                    about these demeaning comments, then grabbed his purse and
                    took out some gold coins. "This should cover your expenses,
                    and I'm quite capable of working." The man's smirk turned
                    into a smile. He took the gold and said, "Yes this covers it
                    just fine. My name is Jacob Bootsma. We are Dutch traders.
                    We leave tomorrow morning at the break of dawn. If you're
                    not there we'll leave without you and with your gold." "I'll
                    be there" Shimizu said.
                </ParagraphStyled>
                <ParagraphStyled>
                    "You really don't want to go out to sea tomorrow" a shrill
                    voice said from the crowd behind them, and both men turned
                    to the source in surprise. "And why is that?" Jacob asked
                    mockingly. "Because of the water monk," the man said. "He
                    dwells in the waters again. My nephew lost his ship and two
                    crew members because of the Umibozu." Shimizu had heard of
                    this myth but wasn't a very superstitious man. Jacob told
                    the man to take his fairytales and stick them up a dark
                    place.
                </ParagraphStyled>
                <ParagraphStyled>
                    "So tomorrow morning, dock five," Jacob said to Shimizu. "Or
                    are you scared of imaginary sea ghosts?" "No I'm not, so
                    I'll see you tomorrow. Oh, and next time your colleague
                    wants to ask me what I want," Shimizu said in a firm voice
                    "I'll appreciate him not associating my mother with that
                    profession." Jacob boomed, "You speak Dutch! You are full of
                    surprises mister Miyamoto. I'm looking forward to our
                    journey. I'll see you tomorrow morning."
                </ParagraphStyled>
                <ParagraphStyled>
                    As Shimizu made to leave the tavern to look for a place to
                    sleep, the stranger who had told them about the Umibozu took
                    his arm and said to him, "If you must go, remember to ignore
                    the Umibozu. If you can manage to do that, it has no power
                    and it will vanish." Shimizu thanked him and left the
                    tavern.
                </ParagraphStyled>
                <ParagraphStyled>
                    After a short and mostly sleepless night, Shimizu gathered
                    his few possessions and started making his way to the docks.
                    When he arrived at the appointed place, the sun wasn't up
                    yet, but there was already a lot of activity going on at
                    dock five. Shimizu looked at the ship that would be his home
                    for the next few months. It was a flute: a long ship with a
                    wide belly, three masts, and a small deck. It wasn't a big
                    ship, so it didn't need a large crew. Two flags fluttered on
                    top of the mast: one Dutch flag and one showing the symbol
                    of the United East Indian Company.
                </ParagraphStyled>
                <ParagraphStyled>
                    In front of the gangway there was some commotion. Four
                    Japanese workers were shouting at Jacob, the tall commander
                    Shimizu had talked to the night before in the tavern. "If
                    you don't get your asses on this ship," Jacob said. "I will
                    hang you onto the stern with a rope tied to one of your arms
                    and drag you out to sea, so it's your choice." The workers
                    seemed to ponder their options and reluctantly started to
                    ascend the gangway.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Good morning" Shimizu said. "Ah mister Miyamoto," Jacob
                    replied. “I was wondering if you would be here in time,
                    considering that you look like you're used to sleeping until
                    the early afternoon.” He grinned. "Well, things aren't
                    always what they seem to be," Shimizu said. "What was all
                    the shouting about?" "It's hard to get good laborers these
                    days. We lost a few crew members due to sickness on our way
                    here so we needed replacements, and we paid these men half
                    of their wages up front. I'll bet they already used that for
                    killing brain cells in the tavern the last few days, and now
                    they refused to come with us because they heard rumors about
                    that water priest that old man told us about yesterday."
                    "You mean the Umibozu," Shimizu said. "Umibollocks if you
                    ask me," Jacob said, laughing. "Now if you would be so kind
                    as to get on board mister Miyamoto," Jacob said with a mock
                    bow. "We're ready to leave, and I can't wait to get of this
                    island. I've been away from home far too long." Shimizu got
                    on board, and as the ship left the harbor, Shimizu took one
                    last look at his native soil, hoping he had made the right
                    decision.
                </ParagraphStyled>
                <ParagraphStyled>
                    As the day passed, he retreated to his bunk, took out his
                    journal, and started writing.
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>June 23rd, 1824</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        Finally on my way. The sea is surprisingly serene today,
                        and though it is a somewhat hostile environment, I'm
                        glad the distance between me and my deficient life back
                        in Japan is growing by the minute. I say hostile because
                        there is a lot of tension going on between the crew
                        members. After we left, some of the crew asked me about
                        the disagreement between Jacob and the Japanese workers.
                        I explained what was going on and told them about the
                        legend of the Umibozu:
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        The Umibozu is a mythical creature that dwells in the
                        ocean. It's a leviathan. Its head has the shape of a
                        monk; it has a slick grey or black, cloudlike torso;
                        enormous glowing eyes; and serpentine limbs. It rises
                        from calm waters. Its presence can be detected in the
                        form of huge black shapes under water or inexplicable
                        disturbances on the water, and it can be accompanied by
                        other strange ocean phenomena, or even just feelings of
                        dread. It will capsize a ship if anyone on it dares to
                        speak of it. It is told that if nobody pays attention to
                        it, it has no power and it will vanish. If it does get
                        attention, however, things can get unpleasant, to say
                        the least. It will ask for barrels so it can put people
                        in them and let them fill with seawater so the people
                        drown. The legend says to give the Umibozu bottomless
                        barrels to avoid such a miserable death.{" "}
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        As I finished my story, the strangest thing happened.
                        Some of the crew started laughing, but some of them
                        looked scared and started praying to their Christian
                        god. The laughing crew members began to tease the scared
                        ones and the Japanese workers. This caused a
                        polarization of the crew. I hope this will not turn out
                        to be a problem on our journey. I talked to Jacob about
                        this, but he just laughed and said that there is always
                        pestering between the crew members, so it would be no
                        problem. I sure hope he is right.{" "}
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>June 24th, 1824</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        As I feared, things haven’t gotten any better today. As
                        I got up, the Japanese workers asked me why I had been
                        so dumb as to tell the Dutchmen the story of you know
                        what. As a Japanese person, I should know better than to
                        disrespect such an atrocity. They actually believe in
                        this nonsense, I thought, and I asked them if they
                        thought the story of the Umibozu was true. Then, before
                        I knew what was happening, one of the workers punched me
                        hard in the face and told me to never talk of it again
                        if I knew what was good for me. Unbelievable, but I
                        don't want too much trouble on my journey, so I told
                        them I will remember not to. The atmosphere between the
                        other crew members wasn't much better, and things are
                        starting to run amok between them. I'm tired of the hard
                        work on board of this ship, so I'm going to get as much
                        sleep as possible so I can cope with what will be
                        waiting for me tomorrow.{" "}
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>June 25th, 1824</em>
                </ParagraphStyled>
                <ParagraphStyled>
                    <em>
                        Things are really getting out of hand now. I've never
                        seen so much violence in one place. Part of the crew
                        just kept on bullying the Japanese workers. One of the
                        bullies walked to the side of the ship and started
                        shouting the name of the Umibozu. At this, one of the
                        workers rushed to the shouting sailor and grabbed him,
                        making both men fall into the sea. We searched for them,
                        looking over the edge, but they were gone, and we
                        haven’t seen them since. After that, some of the crew
                        hauled up three wooden barrels and forced the three
                        remaining Japanese workers into them, just to teach them
                        a lesson, I guess. Meanwhile, the weather was changing,
                        and all of a sudden the sea was stormy. When the sailors
                        were putting the lids on the barrels, I made my way to
                        Jacob to tell him what was going on. On my way to his
                        cabin, a large wave swept the barrels from the deck and
                        into the sea, with the Japanese workers in them. The
                        huge waves crushed the barrels, and the workers drowned.
                        This is going to sound ridiculous, but I could swear I
                        saw the silhouette of a giant round head behind those
                        waves. Jacob rushed by me and grabbed the first crew
                        member he saw by the throat and asked what the... Well,
                        he just asked in a rude way what was going on. The whole
                        crew started shouting incomprehensibly and blaming one
                        another. Jacob tried to restore order but it was in
                        vain. It seemed the crew was possessed, and they just
                        began to attack each other. So within a short period all
                        of them were fighting with knives and all that they
                        could find to hurt or even kill each other. All this
                        time the storm was getting worse, people were being
                        tossed around like rag dolls by the waves, and some of
                        them ended up making their final trip: a one-way ticket
                        to the depths of the savage sea. I had to get out of
                        there to, if I don't survive this madness, write down
                        what happened here...{" "}
                    </em>
                </ParagraphStyled>
                <ParagraphStyled>
                    Then Shimizu heard a loud voice, "Mister Miyamoto, where are
                    you? Come out and see what you and your rotten country
                    fables have caused!" It was the unmistakable voice of Jacob
                    and it sounded angry. Before Shimizu could act, he felt a
                    blow to his head. Jacob took Shimizu's journal in one hand
                    and his left foot in the other and hauled his body onto the
                    deck. Shimizu’s sight was blurry due to the hit on his head,
                    but he could see that a total carnage had taken place while
                    he had been writing in his journal. All of the crew, of
                    which there weren't many left, lay dead on the ship's deck.
                    He saw limbs and even severed heads all around the ship.
                    "You see what you have made them do with your fairytales?!"
                    Jacob demanded.
                </ParagraphStyled>
                <ParagraphStyled>
                    Jacob frantically paged through the journal and then threw
                    it at Shimizu. "My whole crew died," Jacob said in a fierce
                    voice. "And I think you are part of the reason why. I was
                    well on my way back to my girlfriends after months, and now
                    I don't even have a crew to get there." "You poisoned my
                    crew with an ancient myth created by your feeble Japanese
                    minds." The storm was still thriving, and at the moment
                    Jacob picked up a piece of wood with which to smash in
                    Shimizu's head, Shimizu saw a large round head with big
                    glowing eyes emerge out of the swirling sea, its long
                    tentacles reaching for the ship. Shimizu stared in terror at
                    the monstrosity. Jacob looked at Shimizu and then in the
                    direction he was looking at. "What the fuck are you looking
                    at? Are you afraid of waves? It's only water. I’ll give you
                    something to be scared of." Jacob took Shimizu's head, and
                    as he lifted his other hand to deal Shimizu a fatal blow,
                    Shimizu managed to escape Jacob’s grip and, in one quick
                    movement, pushed Jacob over the ledge of the ship. As Jacob
                    fell, he shouted something inaudible before vanishing
                    forever. Shimizu got to his feet, peering down at the stormy
                    waves, but he saw no sign of the leviathan he had just seen,
                    or did he? He turned around and then slipped in someone’s
                    bowels. As he fell, his head hit the railing, and everything
                    went black.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Ship ahoy!" a voice shouted. It was a Japanese trading ship
                    on its way back to Nagasaki. The sea was calm and the sun
                    was shining. The trading ship came up alongside the
                    abandoned vessel, and the men got on board. The captain
                    looked in horror at the massacre he saw. "We got a survivor
                    sir," a sailor shouted. The men took Shimizu to the captain
                    as he revived. "Just ignore it, just ignore it, please!"
                    Shimizu said. "What's that all about?" a sailor asked.
                    "Maybe he means your wife's talking; that's what we did
                    before you married her" another sailor joked. At this, the
                    whole crew laughed and the sailor stormed at the joker.
                    "Break it up, or I'll have you both swim back to Nagasaki"
                    the captain said. "I found this lying next to him sir" a
                    crew member said as he handed Shimizu's journal to the
                    captain. The captain read through the journal and then
                    sighed. "Well well, looks like a case of mass insanity to
                    me. It says that the crew went berserk over the story of the
                    Umibozu..." Hearing that, Shimizu tried to grab the
                    captain’s throat with both hands. "Ignore it, you hear me?
                    You don't want to tempt the beast!" The crew members were
                    just in time to stop Shimizu. "Take this madman aboard our
                    ship and tie him up in the cargo hold. We’ll have a lot of
                    questions for him when we are back ashore."
                </ParagraphStyled>
                <ParagraphStyled>
                    Back on the Japanese trading ship, Shimizu was locked up in
                    the cargo hold. One of the sailors, one who hadn't joined
                    the crew on the Dutch ship, asked what had happened, looking
                    anxiously at the carnage on the other ship. "They all went
                    crazy over some stupid story about the Umibozu, I think," a
                    sailor said, laughing. "Don't joke about that," someone
                    said. "Yeah don't mention that name again if you want to
                    keep your ugly face bruise free" another replied. "You can’t
                    be serious guys. You actually believe this bullshit?" the
                    sailor asked in disbelief. "Try to say its name again and
                    see what happens, you asshole" a scared-looking sailor
                    stated.
                </ParagraphStyled>
                <ParagraphStyled>
                    "Set sail now. We have to get back to Nagasaki as soon as
                    possible," the captain said to his commanding officer.
                    "Looks like there’s a storm coming . . . ”
                </ParagraphStyled>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StoryUmibozu;
