import React, { useEffect, useContext } from "react";
import TableSection from "./TableSection";
import { ShowsContext } from "../../context/ShowsContext";
import * as yup from "yup";

const headCells = [
    {
        id: "id",
        numeric: true,
        disablePadding: false,
        label: "ID",
        type: "string",
    },
    {
        id: "title",
        numeric: false,
        disablePadding: false,
        label: "Info",
        type: "string",
    },
    {
        id: "date",
        numeric: true,
        disablePadding: false,
        label: "Date",
        type: "date",
    },
    {
        id: "venue",
        numeric: true,
        disablePadding: false,
        label: "Venue",
        type: "string",
    },
    {
        id: "city",
        numeric: true,
        disablePadding: false,
        label: "City",
        type: "string",
    },
    {
        id: "country",
        numeric: true,
        disablePadding: false,
        label: "Country",
        type: "string",
    },
    {
        id: "cancelled",
        numeric: false,
        disablePadding: false,
        label: "Cancelled",
        type: "boolean",
    },
];

const validationSchemaAdd = yup.object().shape({
    title: yup.string().required("required"),
    venue: yup.string().required("required"),
    city: yup.string().required("required"),
    country: yup.string().required("required"),
});

const validationSchemaUpdate = yup.object().shape({
    title: yup.string().required("required"),
    venue: yup.string().required("required"),
    city: yup.string().required("required"),
    country: yup.string().required("required"),
});

const ShowsTable = () => {
    const { shows, fetchShowsList, addShow, updateShow, deleteShow } =
        useContext(ShowsContext);
    const showsMapped = shows?.map((gig) => {
        return {
            id: gig.id,
            title: gig.title,
            date: gig.date,
            venue: gig.venue,
            city: gig.city,
            country: gig.country,
            cancelled: !!gig.cancelled,
        };
    });

    useEffect(() => {
        if (showsMapped?.length === 0) {
            fetchShowsList();
        }
    }, []);

    return (
        <TableSection
            headCells={headCells}
            validationSchemaAdd={validationSchemaAdd}
            validationSchemaUpdate={validationSchemaUpdate}
            titlePlural={"Shows"}
            titleSingle={"show"}
            defaultSortField={"date"}
            data={showsMapped}
            onAddEvent={addShow}
            onUpdateEvent={updateShow}
            onDeleteEvent={deleteShow}
        />
    );
};

export default ShowsTable;
