import React, { useReducer, createContext, useEffect } from "react";
import { showsReducer, initialState } from "./reducers/showsReducer";
import httpClient from "../auth/httpClient";

export const ShowsContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const ShowsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(showsReducer, initialState);

    const fetchShowsList = async () => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.get("/api/gigs");
            dispatch({ type: "FETCH_LIST", payload: result.data });
        } catch (error) {
            dispatch({ type: "FETCH_LIST_ERROR", payload: error.data });
        }
    };

    const addShow = async (show) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.post("/api/gigs", {
                show,
            });
            dispatch({ type: "ADD_SHOW", payload: result.data });
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    const updateShow = async (show) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.patch(`/api/gigs/${show.id}`, {
                show,
            });
            if (result?.status === 200) {
                dispatch({ type: "UPDATE_SHOW", payload: result.data });
            }
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    const deleteShow = async (show) => {
        dispatch({ type: "SET_LOADING", payload: true });
        try {
            const result = await httpClient.delete(`/api/gigs/${show.id}`);
            dispatch({ type: "DELETE_SHOW", payload: result.data });
        } catch (error) {
            dispatch({ type: "MUTATION_ERROR", payload: error.data });
        }
    };

    return (
        <ShowsContext.Provider
            value={{
                shows: state.shows,
                fetchShowsList,
                addShow,
                updateShow,
                deleteShow,
            }}
        >
            {children}
        </ShowsContext.Provider>
    );
};
