import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackButtonStyled = styled(Link)`
    position: absolute;
    transform: translate(-25px, 3px);
    color: white;
    font-size: min(5vw, 15px);
`;

const ButtonIcon = styled(FontAwesomeIcon)`
    border-radius: 10px;
    opacity: 0.55;

    :hover {
        box-shadow: 0px 0px 5px 1px white;
        opacity: 1;
    }
`;

const BackButton = ({ route }) => {
    return (
        <BackButtonStyled to={route}>
            <ButtonIcon icon={faArrowAltCircleLeft} />
        </BackButtonStyled>
    );
};

export default BackButton;
