import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import illumniButton from "./../../media/illumni-button.png";
import lizardButton from "./../../media/el-lizard-birth-button.png";
import svartalfrButton from "./../../media/svartalfr-button.png";
import supernaturalButton from "./../../media/supernatural-selection-button.png";
import thinLineButton from "./../../media/a-thin-line-button.png";
import umibozuButton from "./../../media/umibozu-button.png";
import BackButton from "../components/BackButton";
import { routes } from "./../routes";
import styled from "styled-components";
import MainPageLayout from "../components/MainPageLayout";
import AnimatedElement from "../components/AnimatedElement";
import SectionHeader from "../components/SectionHeader";

const StoriesLayout = styled.div`
    margin: 10vh 2vw 5vh 2vw;
    display: flex;
    flex-direction: column;
    width: min(32rem, 80%);
    height: fit-content;
`;

const LinkStyled = styled(Link)`
    padding: 1rem;
    max-width: 100%;
    display: flex;
    justify-content: center;
`;

const ImgStyled = styled.img`
    border-radius: 25px;
    max-width: min(90%, 7rem);

    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 0.7;
        }
    }

    :hover {
        box-shadow: 0px 0px 5px 1px white;
        opacity: 1;
    }
`;

const LinkWrapper = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
`;

const StorySelectionPage = () => {
    const storiesRef = useRef(null);

    useEffect(() => {
        setAnimationDelays();
    });

    const setAnimationDelays = () => {
        const divs = storiesRef.current.children;
        for (let i = 0; i < divs.length; i++) {
            divs[i].children[0].style["animationDelay"] = `${2 * i + 3}00ms`;
        }
    };

    return (
        <MainPageLayout>
            <StoriesLayout>
                <AnimatedElement>
                    <SectionHeader>
                        <BackButton route={routes.stories} y={-3} />
                        Select a story:
                    </SectionHeader>
                </AnimatedElement>
                <AnimatedElement>
                    <LinkWrapper ref={storiesRef}>
                        <LinkStyled to={routes.illumniFhtagn}>
                            <ImgStyled src={illumniButton} />
                        </LinkStyled>
                        <LinkStyled to={routes.elLizardBirth}>
                            <ImgStyled src={lizardButton} />
                        </LinkStyled>
                        <LinkStyled to={routes.svartalfr}>
                            <ImgStyled src={svartalfrButton} />
                        </LinkStyled>
                        <LinkStyled to={routes.superNaturalSelection}>
                            <ImgStyled src={supernaturalButton} />
                        </LinkStyled>
                        <LinkStyled to={routes.aThinLine}>
                            <ImgStyled src={thinLineButton} />
                        </LinkStyled>
                        <LinkStyled to={routes.umibozu}>
                            <ImgStyled src={umibozuButton} />
                        </LinkStyled>
                    </LinkWrapper>
                </AnimatedElement>
            </StoriesLayout>
        </MainPageLayout>
    );
};

export default StorySelectionPage;
