import React, { useReducer, createContext } from "react";
import { settingsReducer, initialState } from "./reducers/settingsReducer";

export const SettingsContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const SettingsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(settingsReducer, initialState);

    const setStoryFontSize = (fontSize) => {
        localStorage.setItem("settings.storyFontSize", fontSize);

        dispatch({ type: "UPDATE_FONTSIZE", payload: fontSize });
    };

    return (
        <SettingsContext.Provider
            value={{
                storyFontSize: state.storyFontSize,
                setStoryFontSize,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
