import React from "react";
import styled from "styled-components";

const ResponsiveTableContainer = styled.div`
@media only screen and (max-width: 760px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block !important;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border-top: 1px solid #cccccc8c;
    }

    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eeeeee21;
        position: relative;
        line-height: 2 !important;
        padding: 0 0 0 50% !important;
    }

    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 3px;
        left: 15px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        font-size: smaller;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
        content: "${(props) => props.headCells?.[0]?.label}";
    }
    td:nth-of-type(2):before {
        content: "${(props) => props.headCells?.[1]?.label}";
    }
    td:nth-of-type(3):before {
        content: "${(props) => props.headCells?.[2]?.label}";
    }
    td:nth-of-type(4):before {
        content: "${(props) => props.headCells?.[3]?.label}";
    }
    td:nth-of-type(5):before {
        content: "${(props) => props.headCells?.[4]?.label}";
    }
    td:nth-of-type(6):before {
        content: "${(props) => props.headCells?.[5]?.label}";
    }
    td:nth-of-type(7):before {
        content: "${(props) => props.headCells?.[6]?.label}";
    }
    td:nth-of-type(8):before {
        content: "${(props) => props.headCells?.[7]?.label}";
    }
    td:nth-of-type(9):before {
        content: "${(props) => props.headCells?.[8]?.label}";
    }
`;

export default ResponsiveTableContainer;
