import React, { useState, useRef } from "react";
import { routes } from "./../routes";
import { Link } from "react-router-dom";
import logoCenter from "./../../media/web-button-center-cropped.png";
import navButtonAgenda from "./../../media/web-button-agenda-cropped.png";
import navButtonPortals from "./../../media/web-button-portals-cropped.png";
import navButtonSaga from "./../../media/web-button-saga-cropped.png";
import navButtonStore from "./../../media/web-button-store-cropped.png";
import styled from "styled-components";

const CenterImageStyled = styled.img`
    width: 6rem;
`;

const MenuImageStyled = styled.img`
    width: 7rem;
`;

const MainPageLayout = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
`;

const HomeMenuStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 7rem);
    grid-template-rows: repeat(3, 7rem);
    border-radius: 50%;
    transition: transform 1s;
`;

const HomeMenuCenterStyled = styled.div`
    justify-self: center;
    align-self: center;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    transition: transform 1s;

    &:hover {
        opacity: 1;
    }
`;

const HomeMenuItemStyled = styled(Link)`
    opacity: 0.7;
    position: relative;
    justify-self: center;
    align-self: center;

    &:hover {
        opacity: 1;
    }

    &:focus {
        opacity: 1;
    }

    &:nth-child(1) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        top: 40px;
    }

    &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        transform: rotate(90deg);
        left: -40px;
    }

    &:nth-child(3) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
        transform: rotate(180deg);
        top: -40px;
    }

    &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        transform: rotate(270deg);
        left: 40px;
    }
`;

const HomeMenu = () => {
    const [degrees, setDegrees] = useState(90);
    const menuRef = useRef();
    const centerRef = useRef();

    const rotateMenu = () => {
        menuRef.current.style.transform = `rotate(${degrees}deg)`;
        centerRef.current.style.transform = `rotate(-${degrees}deg)`;

        setDegrees(degrees === 990 ? 0 : degrees + 90);
    };

    return (
        <MainPageLayout>
            <HomeMenuStyled ref={menuRef}>
                <HomeMenuItemStyled to={routes.bio}>
                    <MenuImageStyled src={navButtonPortals} />
                </HomeMenuItemStyled>
                <HomeMenuItemStyled to={routes.shows}>
                    <MenuImageStyled src={navButtonAgenda} />
                </HomeMenuItemStyled>
                <HomeMenuItemStyled to={routes.stories}>
                    <MenuImageStyled src={navButtonSaga} />
                </HomeMenuItemStyled>
                <HomeMenuItemStyled
                    to={{ pathname: routes.store }}
                    target="_blank"
                >
                    <MenuImageStyled src={navButtonStore} />
                </HomeMenuItemStyled>
                <HomeMenuCenterStyled
                    ref={centerRef}
                    onClick={() => rotateMenu()}
                >
                    <CenterImageStyled src={logoCenter} />
                </HomeMenuCenterStyled>
            </HomeMenuStyled>
        </MainPageLayout>
    );
};

export default HomeMenu;
