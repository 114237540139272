import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(400px, 75%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const generateDefaultValues = (headerProps) => {
    const defaultValues = {};

    headerProps.forEach((header) => {
        if (header.id === "id") return;
        switch (header.type) {
            case "string":
                defaultValues[header.id] = "";
                break;
            case "password":
                defaultValues[header.id] = "";
                break;
            case "number":
                defaultValues[header.id] = 0;
                break;
            case "boolean":
                defaultValues[header.id] = false;
                break;
            case "date":
                defaultValues[header.id] = dayjs()
                    .format("YYYY-MM-DD")
                    .toString();
                break;
            default:
                return;
        }
    });

    return defaultValues;
};

const generateFormFields = (headerProps, formik) => {
    const formFields = [];

    headerProps.forEach((header) => {
        if (header.id === "id") return;
        switch (header.type) {
            case "string":
                formFields.push(
                    <TextField
                        key={`formfield-add-${header.id}`}
                        id={header.id}
                        name={header.id}
                        label={header.label}
                        type={header.type}
                        value={formik.values[header.id]}
                        onChange={formik.handleChange}
                        sx={{ marginBottom: 1 }}
                        error={
                            formik.touched[header.id] &&
                            Boolean(formik.errors[header.id])
                        }
                        helperText={
                            formik.touched[header.id] &&
                            formik.errors[header.id]
                        }
                    />
                );
                break;
            case "password":
                formFields.push(
                    <TextField
                        key={`formfield-add-${header.id}`}
                        id={header.id}
                        name={header.id}
                        label={header.label}
                        type={header.type}
                        value={formik.values[header.id]}
                        onChange={formik.handleChange}
                        sx={{ marginBottom: 1 }}
                        error={
                            formik.touched[header.id] &&
                            Boolean(formik.errors[header.id])
                        }
                        helperText={
                            formik.touched[header.id] &&
                            formik.errors[header.id]
                        }
                    />
                );
                break;
            case "number":
                formFields.push(
                    <TextField
                        key={`formfield-add-${header.id}`}
                        id={header.id}
                        name={header.id}
                        label={header.label}
                        type={header.type}
                        value={formik.values[header.id]}
                        onChange={formik.handleChange}
                        sx={{ marginBottom: 1 }}
                        error={
                            formik.touched[header.id] &&
                            Boolean(formik.errors[header.id])
                        }
                        helperText={
                            formik.touched[header.id] &&
                            formik.errors[header.id]
                        }
                    />
                );
                break;
            case "boolean":
                formFields.push(
                    <FormControlLabel
                        key={`formfield-add-${header.id}`}
                        value="start"
                        control={
                            <Checkbox
                                id={header.id}
                                name={header.id}
                                onChange={formik.handleChange}
                                color="primary"
                                checked={formik.values[header.id]}
                                value={formik.values[header.id]}
                            />
                        }
                        label={header.label}
                        labelPlacement="start"
                        sx={{
                            justifyContent: "space-between",
                            margin: "0 0 8px 14px",
                        }}
                    />
                );
                break;
            case "date":
                formFields.push(
                    <React.Fragment key={`formfield-add-${header.id}`}>
                        <DatePicker
                            id={header.id}
                            name={header.id}
                            value={formik.values[header.id]}
                            onChange={(dateObject) => {
                                formik.setFieldValue(
                                    header.id,
                                    dayjs(dateObject)
                                        .format("YYYY-MM-DD")
                                        .toString()
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ marginBottom: 1 }}
                                />
                            )}
                        />
                    </React.Fragment>
                );
                break;
            default:
                return;
        }
    });

    return formFields;
};

export default function AddModal({
    open,
    handleClose,
    headers,
    extraCrudFields,
    validationSchema,
    onSubmit,
    titleSingle,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: generateDefaultValues(
            extraCrudFields ? [...headers, ...extraCrudFields] : headers
        ),
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);
            onSubmit(values).finally(() => {
                setIsLoading(false);
                handleClose();
                resetForm();
            });
        },
    });

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Fade in={open}>
                            <Box sx={style}>
                                <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    sx={{ maskBorderSlice: 2, marginBottom: 2 }}
                                >
                                    {`Add a ${titleSingle}`}
                                </Typography>
                                <FormControl sx={{ minWidth: "100%" }}>
                                    {generateFormFields(
                                        extraCrudFields
                                            ? [...headers, ...extraCrudFields]
                                            : headers,
                                        formik
                                    )}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <Button
                                            onClick={handleClose}
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: "40%" }}
                                        >
                                            Cancel
                                        </Button>
                                        {isLoading ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ minWidth: "40%" }}
                                            >
                                                <CircularProgress
                                                    size={20}
                                                    sx={{
                                                        color: "black",
                                                    }}
                                                />
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                sx={{ minWidth: "40%" }}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                </FormControl>
                            </Box>
                        </Fade>
                    </form>
                </Modal>
            </LocalizationProvider>
        </div>
    );
}
